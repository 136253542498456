import React, {useEffect} from 'react';
import { Checkbox} from '@mui/material';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";


export default function Consent({label, approved, missingRequiredFields ,changeApproved}) {
  const { t } = useTranslation();

  const handleChangeApproved = (e) => {
    changeApproved(e.target.checked);
  };

  useEffect(() => {
    const termsElement = document.getElementById("terms");
    const termsError = termsElement.closest(".consent").querySelector(".error");

    if(missingRequiredFields['consent'] === true){
      termsError.classList.remove("hidden");
    }else{
      termsError.classList.add("hidden");
    }

  }, [missingRequiredFields]);

  return (  
        <div className='consent'>
            <div className='content'>
            <Checkbox 
                id="terms" 
                onChange={(e) => handleChangeApproved(e)}
                checked={approved}
                 /> 
            <div>{label}</div>
            </div>
            <div className='error hidden'>{t("Please approve this field")}</div>
        </div>
  );
}
