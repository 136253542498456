import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, ALL, CALL_TYPE_ALL} from '../Constants';
import { Typography, Container} from '@mui/material';
import {Box} from '@mui/material';
import '../styles.css';
import cookie from "js-cookie";
import Subscription from "../components/Subscription";
import EditLineInfo from "../components/EditLineInfo";
import Package from "../components/Package";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import {handleApiErrorMessage} from "../Helper";
import "react-datepicker/dist/react-datepicker.css";


export default function LineDetails(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lineDetails, setLineDetails] = useState({});
  const [packages, setPackages] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(loading){
      return () => {};
    }
    getLineDetails(); 
  }, []);

  const getLineDetails = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/lines/getLine?line_id='+props.line.id;

    axios.get(apiUrl, {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("line_data"))){
          setLineDetails(data.line_data);
          if(data.hasOwnProperty("packages")){
            setPackages(data.packages);
          }
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };


  return (  
    <Container className="content-wrapper line-details">
          <>
            <Box sx={{mb: 4, ml: "20px"}} className="line-detail-wrrapper">
              <Box sx={{mb: 2}}>
                  <Typography variant="h5" sx={{mb: 1, color: "#0077ff"}}><strong>{t('Line Details')}</strong></Typography>
              </Box>
              <EditLineInfo line={lineDetails}></EditLineInfo>
              <Subscription subscription={lineDetails} isMain={true} key={lineDetails.id} />
              {
                lineDetails.hasOwnProperty("secondarySubscriptions") ? 
                lineDetails.secondarySubscriptions.map(function(secondarySubscription, i){
                  return <Subscription subscription={secondarySubscription} isMain={false} key={secondarySubscription.id} />;
                }) : ''
              }
            </Box>
            <Box sx={{mb: 4}}>
              <Box sx={{mb: 2}}>
                  <Typography variant="h5"  sx={{mb: 1, color: "#0077ff"}}><strong>{t('Packages')}</strong></Typography>
              </Box>
              {
                packages.length === 0 ?
                <Typography>{t('No packages found for the line')}</Typography> :
                packages.map(function(currentPackage, i){
                  return <Package package={currentPackage} key={currentPackage.customer_package_id} />;
                })
              }
            </Box>
            <div style={{height: "20px"}}></div>
          </>
    </Container>
  );
}
