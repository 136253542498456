import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION, WATCH_STATUS_ON, WATCH_STATUS_OPEN, ALL, WATCH_STATUS_CALLS_IN , WATCH_SERVICE_CALLS, WATCH_SERVICE_SMS} from '../Constants';
import { WATCH_STATUS_CALLS_OUT, WATCH_STATUS_CLOSED, WATCH_STATUS_BLOCKED } from '../Constants';
import { Box, Select, MenuItem, Button, Backdrop, CircularProgress, Collapse, IconButton, Chip, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import {handleApiErrorMessage} from "../Helper"
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import '../styles.css';
import ExcludedNumbers from "./ExcludedNumbers";
import Profile from "./Profile";

export default function WatchSystem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [watchStatus, setWatchStatus] = useState(WATCH_STATUS_ON);
  const [watchServiceType, setWatchServiceType] = useState(WATCH_SERVICE_CALLS);
  const [isAllowToSmsWatchSystem, setIsAllowToSmsWatchSystem] = useState(false);

  let initialWatchProfile = {};
  for(let dayIndex=0; dayIndex<7; dayIndex++){
    initialWatchProfile[''+dayIndex] = {};
    for(let hourIndex=0; hourIndex<24; hourIndex++){
      initialWatchProfile[''+dayIndex][''+hourIndex] = {};
      initialWatchProfile[''+dayIndex][''+hourIndex]['status'] = WATCH_STATUS_OPEN;
      initialWatchProfile[''+dayIndex][''+hourIndex]['calls'] = ALL;
    }
  }
  const [watchProfile, setWatchProfile] = useState(null);
  const [excludeNumbers, setExcludeNumbers] = useState([]);

  let initialCollapsedValue = {};
  for(let dayIndex = 0; dayIndex < 7; dayIndex++){
    initialCollapsedValue['' + dayIndex] = false;
  }
  const [loaderVisible, setLoaderVisible] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const defaultWarningMessageTitle = t("Warning! There has been errors in your profile, therefore the system saved the closest valid profile.");
  const [warningMessageTitle, setWarningMessageTitle] = useState(defaultWarningMessageTitle);
  const [warningMessageErrors, setWarningMessageErrors] = useState([]);

  const dayNumberToTitle = {
    "0": t("Sunday"),
    "1": t("Monday"),
    "2": t("Tuesday"),
    "3": t("Wednesday"),
    "4": t("Thursday"),
    "5": t("Friday"),
    "6": t("Saturday")
  };
    
  useEffect(() => {
    if(typeof props.entityType === 'undefined'){
      return () => {};
    }

    let  headers = {
      token: cookie.get('token') 
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let apiUrl = API_URL + '/watchSystem/';
    if(props.entityType === "customer"){
      apiUrl += `getCustomerProfile/?service=${watchServiceType}`;
    }else{
      apiUrl += 'getLineProfile/';
      apiUrl += '?line_id='+props.lineId+`&service=${watchServiceType}`;
    }

    axios.get(apiUrl, {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK)){
            setWatchStatus(data.watchStatus);
            setExcludeNumbers(data.exclude_numbers);
            if(data.profile !== null){
              setWatchProfile(data.watchProfile);
            }else{
              setWatchProfile(initialWatchProfile);
            }
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
  }, [props.entityType, watchServiceType]);

    // בפונקציה לטעינת המשתנה
    const loadIsAllowToSmsWatchSystem = () => {
      let headers = {
        token: cookie.get('token') 
      };
      let apiUrl = API_URL + '/my';

      axios.get(apiUrl, {headers})
        .then(({ data }) => {
          if (data.hasOwnProperty("responseStatus") && data['responseStatus'] === API_OK && data['smsWatchSystemAllow'] === true) {
            setIsAllowToSmsWatchSystem(true);
          } else {
            setIsAllowToSmsWatchSystem(false);
          }
        })
        .catch((error) => {
          setIsAllowToSmsWatchSystem(false);
        });
    };

    useEffect(() => {
      loadIsAllowToSmsWatchSystem();
    }, [isAllowToSmsWatchSystem]);


  const deleteExcludedNumber = (numberIndex) => {
    let newExcludedNumbers = [...excludeNumbers];
    newExcludedNumbers.splice(numberIndex, 1);
    setExcludeNumbers(newExcludedNumbers);
  };


  const addExcludedNumber = (newExcludedNumber) => {
    let newExcludeNumbers = [...excludeNumbers];
    newExcludeNumbers.push(newExcludedNumber);
    setExcludeNumbers(newExcludeNumbers);
};
  
  const handleSave = () => {
    setLoaderVisible(true);

    setShowWarningMessage(false);
    setShowErrorMessage(false);
    setShowSuccessMessage(false);

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let params = {status: watchStatus, profile: watchProfile, exclude_numbers: excludeNumbers, service: watchServiceType};

    let apiUrl = API_URL + '/watchSystem/';
    if(props.entityType === "customer"){
      apiUrl += 'setCustomerProfile/';
    }else{
      apiUrl += 'setLineProfile/';
      params.line_id = props.lineId;
    }

    axios.put(apiUrl, params, {headers})
    .then(
      async (responseData) => {
        if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
          if(responseData.data.valid_profile_status === "OK"){
            setLoaderVisible(false);
            setShowSuccessMessage(true);
          }else{
            if(responseData.data.valid_profile_status === "FIX"){
              setWarningMessageErrors(responseData.data.valid_profile_problems);
              setLoaderVisible(false);
              setShowWarningMessage(true);
            }
            if(responseData.data.valid_profile_status === "MIS" || responseData.data.valid_profile_status === "ERROR"){
              setLoaderVisible(false);
              setShowErrorMessage(true);
            }
            setWatchProfile(responseData.data.profile);
            setWatchStatus(responseData.data.status);
          }
        }else{
          if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
            errorMessage = responseData.data["message"];
          }
          setErrorMessage(errorMessage);
          setShowErrorMessage(true);
          setLoaderVisible(false);
        }
      }).catch((error) => {
        setErrorMessage(errorMessage);
        setShowErrorMessage(true);
        setLoaderVisible(false);
    });
  }


  const warningMessageErrorLines = () => {
    let errorLines = [];
    for(let errorIndex=0; errorIndex < warningMessageErrors.length; errorIndex++){
      errorLines.push(<li className='warning-message-error' key={'warning-message-error-'+errorIndex}>{warningMessageErrors[errorIndex]}</li>);
    }
    return errorLines;
  };

    return (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={[
          {
            mt: 2,
            mb: 4
        }
      ]}
        >
          <Box maxWidth={'1000px'}>
          <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
            <div>{successMessage}</div>
            <IconButton onClick={() => setShowSuccessMessage(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
            <div>{errorMessage}</div>
            <IconButton onClick={() => setShowErrorMessage(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="warning-message" sx={{ flexGrow: 1, display: (showWarningMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
            <div className='warning-message-content'>
              <div className='warning-message-title'>{warningMessageTitle}</div>
              <ul className='warning-message-errors'>
                {warningMessageErrorLines()}
              </ul>
            </div>
            <IconButton onClick={() => setShowWarningMessage(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          {isAllowToSmsWatchSystem && (
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'flex-start', alignItems: "center" }} className="watch-status-wrapper"> 
              <div className='title'>{t('Watch Service:')}</div>
              <div className='value'>
                <Select
                  size="small"
                  value={watchServiceType}
                  onChange={(e) => {setWatchServiceType(e.target.value)}}
                >
                  <MenuItem value={WATCH_SERVICE_CALLS}>{t("Watch Service Calls")}</MenuItem>
                  <MenuItem value={WATCH_SERVICE_SMS}>{t("Watch Service Sms")}</MenuItem>
                </Select>
              </div>
            </Box>
          )}
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'flex-start', alignItems: "center"  }} className="watch-status-wrapper"> 
          <div className='title'>{t('Watch status:')}</div>
          <div className='value'>
            <Select
              size="small"
              value={watchStatus}
              onChange={(e) => {setWatchStatus(e.target.value)}}
            >
              <MenuItem value={WATCH_STATUS_ON}>{t("Watch is on")}</MenuItem>
              <MenuItem value={WATCH_STATUS_OPEN}>{t("Watch is open")}</MenuItem>
              <MenuItem value={WATCH_STATUS_BLOCKED}>{t("Watch is closed")}</MenuItem>
            </Select>
          </div>
        </Box>
        <Profile 
          setLoaderVisible={setLoaderVisible} 
          setProfile={setWatchProfile}
          profile={watchProfile}
          defaultStatusValue={WATCH_STATUS_OPEN}
          statusValues={{
            [WATCH_STATUS_CLOSED]: t("Closed"),
            [WATCH_STATUS_OPEN]: t("Open")
          }}
          hasCallsValue={true}
          defaultCallsValue={ALL}
          callsValues={{
            ALL : t("All"),
            [WATCH_STATUS_CALLS_OUT]: t("Outcoming calls"),
            [WATCH_STATUS_CALLS_IN]: t("Incoming calls")
          }}
           />
        <ExcludedNumbers 
          addExcludedNumber={addExcludedNumber} 
          deleteExcludedNumber={deleteExcludedNumber}
          excludeNumbers={excludeNumbers}
          label={t("Excluded numbers from the watch:")}
         />
        <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSave(e)}
              sx={{ width: 210, mt: 3 }}
          >
              {t('Save')}
          </Button>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderVisible}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </Box>
    );
}
