import React from 'react';
import { Button, Chip, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../styles.css';

export default function WatchSystem(props) {
  const { t } = useTranslation();

  const getExcludedNumbersChips = (excludeNumbers) => {
    if(excludeNumbers === null){
      return [];
    }
    let numberChips = [];
    for(let numberIndex = 0; numberIndex < excludeNumbers.length; numberIndex++){
      numberChips.push(
        <Chip label={excludeNumbers[numberIndex]} key={excludeNumbers[numberIndex]} 
            onDelete={() => props.deleteExcludedNumber(numberIndex)} />
      );
    }
    return numberChips;
  }

  const handleAddExcludedNumber = (addExcludedNumber) => {
    let inputValue = document.getElementById("excluded_numbers").value;
    if(inputValue === ''){
      return;
    }
    var numericValueRegex = /^\d+$/;
    if(!numericValueRegex.test(inputValue)){
      alert(t("Please insert only numeric values"));
      return;
    }

    addExcludedNumber(inputValue);

    document.getElementById("excluded_numbers").value = "";
};
  

    return (
        <div className="excluded-numbers-wrapper">
          <div className="title">{props.label}</div>
          <div className='chips'>
              {getExcludedNumbersChips(props.excludeNumbers)}
            </div>
          <div className='enter-numbers-wrapper'>
            <TextField  className="input" size="small" id="excluded_numbers" />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddExcludedNumber(props.addExcludedNumber)}

            >
              {t('Add')}
            </Button>
          </div>
        </div>
    );
}
