import React, {useState, useEffect} from 'react';
import { Select, Button, Container, MenuItem, Backdrop, CircularProgress, Checkbox} from '@mui/material';
import { Box, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, PASSWORD_CALL_AUTH_STATUS_ON } from '../../Constants';
import { PASSWORD_CALL_AUTH_STATUS_OFF } from '../../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logout } from "../../features/user";
import {handleApiErrorMessage} from "../../Helper";
import { showErrorDialog } from "../../features/errorDialog";
import Password from "./Password";
import ExcludedNumbers from "../ExcludedNumbers";
import Consent from "../Consent";
import Profile from "../Profile";

export default function Service(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serviceStatus, setServiceStatus] = useState(PASSWORD_CALL_AUTH_STATUS_ON);
  const [excludeNumbers, setExcludeNumbers] = useState([]);
  const [password, setPassword] = useState('');
  const [profile, setProfile] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [removalApproved, setRemovalApproved] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const defaultWarningMessageTitle = t("Warning! There has been errors in your profile, therefore the system saved the closest valid profile.");
  const [warningMessageTitle, setWarningMessageTitle] = useState(defaultWarningMessageTitle);
  const [warningMessageErrors, setWarningMessageErrors] = useState([]);

  const [missingRequiredFields, setMissingRequiredFields] = useState({
    password: false
  });

  const [missingRequiredFieldsInRemoval, setMissingRequiredFieldsInRemoval] = useState({
    consent: false
  });

  const changePassword = (newPassword) => {
    setPassword(newPassword);
  };

  useEffect(() => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    axios.get(API_URL + 'incomingCallsPassword/'+props.lineId+'/profile', {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
          setServiceStatus(data.watchStatus);
          setExcludeNumbers(data.exclude_numbers);
          setPassword(data.password);
          setProfile(data.watchProfile);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
      }).catch(() => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
    });
  }, []);

  const displayBeforeRemovalPopup = () => {
    setRemovalDialogOpen(true);
  }

  const handleSave = () => {
    let missingFields = (password === "");
    setMissingRequiredFields({
      password: (password === "")
    });

    if(missingFields === true){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    let  headers = {
      token: cookie.get('token')
    }
    let params = {
      status: serviceStatus,
      password: password,
      exclude_numbers: excludeNumbers,
      watchProfile: profile
    };
    let apiUrl = API_URL + '/incomingCallsPassword/'+props.lineId+'/profile';

    setLoaderVisible(true);

    axios.put(apiUrl, 
      params, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
            if(data.valid_profile_status === "OK"){
              setLoaderVisible(false);
              setShowSuccessMessage(true);
            }else{
              if(data.valid_profile_status === "FIX"){
                setWarningMessageErrors(data.valid_profile_problems);
                setLoaderVisible(false);
                setShowWarningMessage(true);
              }
              if(data.valid_profile_status === "MIS" || data.valid_profile_status === "ERROR"){
                setLoaderVisible(false);
                setShowErrorMessage(true);
              }
            }
            setProfile(data.profile);
            setServiceStatus(data.status);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
  };

  const warningMessageErrorLines = () => {
    let errorLines = [];
    for(let errorIndex=0; errorIndex < warningMessageErrors.length; errorIndex++){
      errorLines.push(<li className='warning-message-error' key={'warning-message-error-'+errorIndex}>{warningMessageErrors[errorIndex]}</li>);
    }
    return errorLines;
  };

  const addExcludedNumber = (newExcludedNumber) => {
    let newExcludeNumbers = [...excludeNumbers];
    newExcludeNumbers.push(newExcludedNumber);
    setExcludeNumbers(newExcludeNumbers);
};

const deleteExcludedNumber = (numberIndex) => {
  let newExcludedNumbers = [...excludeNumbers];
  newExcludedNumbers.splice(numberIndex, 1);
  setExcludeNumbers(newExcludedNumbers);
};

const handleRemovalDialogClose = () => {
  setRemovalDialogOpen(false);
};

const handleSubmitRemoval = () => {
  setMissingRequiredFieldsInRemoval({
    consent: (!removalApproved)
  });

  let missingFields = (!removalApproved);
  if(missingFields === true){
    return;
  }

  let errorMessage = t(DEFAULT_ERROR_TEXT);

  let  headers = {
    token: cookie.get('token')
  }
  let apiUrl = API_URL + '/incomingCallsPassword/'+props.lineId+'/registration';

  setDialogLoaderVisible(true);

  axios.delete(apiUrl,  
  {headers})
  .then(
    ({ data }) => {
      if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK 
      && data.hasOwnProperty("status") && (data['status'] === true))){
        props.closeDialog();
        setDialogLoaderVisible(false);
        props.showSuccessMessageInLinesScreen(true);
      }else{
        if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
          errorMessage = data["message"];
        }
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setDialogLoaderVisible(false);
      }
    }).catch((error) => {
      handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      setDialogLoaderVisible(false);
  });
};

  return (  
    <Container className="content-wrapper service">
      <div className='status'>
          <div className='title'>{t('Service status:')}</div>
          <div className='value'>
            <Select
              size="small"
              value={serviceStatus}
              onChange={(e) => {setServiceStatus(e.target.value)}}
            >
              <MenuItem value={PASSWORD_CALL_AUTH_STATUS_ON}>{t("The service is on")}</MenuItem>
              <MenuItem value={PASSWORD_CALL_AUTH_STATUS_OFF}>{t("The service is off")}</MenuItem>
            </Select>
          </div>
      </div>
      <Password password={password} missingRequiredFields={missingRequiredFields} changePassword={changePassword} />
      <ExcludedNumbers 
        addExcludedNumber={addExcludedNumber} 
        deleteExcludedNumber={deleteExcludedNumber}
        excludeNumbers={excludeNumbers}
        label={t("Excluded numbers from the service:")}
         />
      <Profile 
        setLoaderVisible={setLoaderVisible} 
        setProfile={setProfile}
        profile={profile}
        defaultStatusValue={PASSWORD_CALL_AUTH_STATUS_ON}
        statusValues={{
          [PASSWORD_CALL_AUTH_STATUS_ON]: t("Open"),
          [PASSWORD_CALL_AUTH_STATUS_OFF]: t("Closed")
        }}
        hasCallsValue={false}
          />
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderVisible}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleSave()}
          className="agree"
      >
          {t('Save')}
      </Button>
      <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div>{successMessage}</div>
        <IconButton onClick={() => setShowSuccessMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div>{errorMessage}</div>
        <IconButton onClick={() => setShowErrorMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="warning-message" sx={{ flexGrow: 1, display: (showWarningMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div className='warning-message-content'>
          <div className='warning-message-title'>{warningMessageTitle}</div>
          <ul className='warning-message-errors'>
            {warningMessageErrorLines()}
          </ul>
        </div>
        <IconButton onClick={() => setShowWarningMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className='removal'>
        <div className='title'>{t('Remove from service')}</div>
        <Button
            variant="contained"
            color="primary"
            onClick={(e) => displayBeforeRemovalPopup()}
        >
            {t('Remove from service')}
        </Button>
      </div>
      <Dialog open={removalDialogOpen} onClose={handleRemovalDialogClose} 
          fullWidth
          maxWidth={'2000px'}
            >
        <DialogTitle>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
            <div>{t("Removal of the line {{line}} from the password service",{line: props.lineNumber} )}</div>
            <IconButton onClick={handleRemovalDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          </DialogTitle>
        <DialogContent >
          <div className='removal-message'>
            <div>{t("Dear customer,")}</div>
            <div>{t("The removal from the service will stop the subscription but will also delete all your settions.")}</div>
            <div>{t("If there is an outstanding payment this month, you will be charged the relative amount.")}</div>
            <div>{t("You can pause the service any time by changing the status in you account.")}</div>
          </div>
          <Consent label={t("I approve the conditions and wish to proceed with the removal.")}
            approved={removalApproved}
            missingRequiredFields={missingRequiredFieldsInRemoval}
            changeApproved={setRemovalApproved}
             />
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmitRemoval()}
            className="agree"
        >
            {t('Submit')}
        </Button>
        </DialogContent>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </Container>
  );
}
