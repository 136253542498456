import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import he from "date-fns/locale/he";
import { API_URL, API_OK, CALL_TYPE_INCOMING, CALL_TYPE_OUTCOMING, API_EXCEPTION, DEFAULT_ERROR_TEXT, ALL, CALL_TYPE_ALL, CALL_TYPE_TRANSFER } from '../Constants';
import { Typography, Container } from '@mui/material';
import { Stack, Button, Select, MenuItem, Box } from '@mui/material';
import { DataGrid, heIL } from '@mui/x-data-grid';
import '../styles.css';
import cookie from "js-cookie";
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../features/errorDialog";
import { handleApiErrorMessage } from "../Helper";
import "react-datepicker/dist/react-datepicker.css";
import FileSaver from 'file-saver';
import * as uuid from 'uuid';
const uuidv4 = uuid.v4;

export default function LineCalls(props) {
  const { t } = useTranslation();

  const initialSearchInput = {
    start_date: '',
    end_date: '',
    call_type: CALL_TYPE_ALL,
    dial: '',
    duration: ''
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const [loadingRecordingIds, setLoadingRecordingIds] = useState([]);
  const [loadedRecordings, setLoadedRecordings] = useState([]);

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  registerLocale("he", he);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: 'callId', headerName: t('Call ID'), width: 130, flex: 1 },
    { field: 'lineNumber', headerName: t('Line Number'), width: 130, flex: 1, hide: !props.line.isMain },
    { field: 'lineUsedName', headerName: t('Line Used Name'), width: 130, flex: 1, hide: !props.line.isMain },
    {
      field: 'dialToOrFrom', headerName: t('Dial To / From'), width: 130, flex: 1,
      valueGetter: (params) => {
        let label = params.row.dialToOrFrom;
        if (params.row.dialToOrFrom === "restricted") label = t("restricted")
        return label;
      }
    },
    { field: 'destinationCountry', headerName: t('Destination country'), width: 130, flex: 1 },
    { field: 'destinationType', headerName: t('Destination type'), width: 130,
        valueGetter: (params) => {
          let label = params.row.destinationType;
          switch (params.row.destinationType) {
            case null:
              label = t("Type unknowed");
              break;
            case "FIXED_LINE_OR_MOBILE":
              label = t("Mobile ot line");
              break;
            case "MOBILE":
              label = t("Mobile");
              break;
            case "TOLL_FREE":
              label = t("Toll Free");
              break;
            case "VOIP":
            case "FIXED_LINE":
            case "SHARED_COST":
              label = t("Fixed line");
              break;
            case "EMERGENCY_FREE":
              label = t("Emergency free");
              break;
            case "EMERGENCY":
              label = t("Emergency");
              break;
          }
          return label;
        }, flex: 1 },
    { field: 'startTime', headerName: t('Start Time'), width: 130, flex: 1 },
    {
      field: 'duration', headerName: t('Call Duration'), width: 130, flex: 1,
      valueFormatter: (params) => {
        return formatTime(params.value)
      },
    },
    {
      field: 'callType', headerName: t('Call Type'), width: 130,
      valueGetter: (params) => {
        let label = params.row.callType;
        switch (params.row.callType) {
          case CALL_TYPE_INCOMING:
            label = t("Incoming");
            break;
          case CALL_TYPE_OUTCOMING:
            label = t("Outcoming");
            break;
          case CALL_TYPE_TRANSFER:
            label = t("Transfer");
            break;
        }
        return label;
      },
      flex: 1, sortable: false
    },
    {
      field: 'recording', headerName: t('Call Recording'), width: 130, flex: 2,
      renderCell: (cellValues) => {
        return (
          (
            loadingRecordingIds.includes(cellValues.row.recordId) ? 
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            : (
              (typeof loadedRecordings.find(value => value.recordingId === cellValues.row.recordId) !== 'undefined') ? 
              <audio controls src={loadedRecordings.find(value => value.recordingId === cellValues.row.recordId).recordingUrl} /> :
              (
                isNaN(cellValues.row.recordId) || cellValues.row.recordId === null ? "" : 
                <img 
                  className="recording playing" 
                  src="design_images/play.svg"
                  onClick={(e) => {
                    setLoadingRecordingIds([...loadingRecordingIds, cellValues.row.recordId]);
                    loadRecording(cellValues.row.lineId, cellValues.row.recordId);
                  }}
                />
              )
            )
          )
        );
      }
    },
    { field: 'usedPackageName', headerName: t('Package Name'), width: 130, flex: 1 },
  ];

  useEffect(() => {
    if (loading) {
      return () => { };
    }
    refreshCallsData();
  }, [page, pageSize]);

  const loadRecording = function(lineId, recordingId) {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let config = {
      headers: {
        token: cookie.get('token'),
        'Content-Type': 'audio/ogg'
      },
      responseType: 'blob'
    }

    let apiUrl = API_URL + '/callsRecords/'+lineId+'/record/'+recordingId;

    axios.get(apiUrl, config)
    .then(
      ({ data }) => {
        var recordingBlob = new Blob([data], {type : 'audio/ogg'});
        var recordingUrl = URL.createObjectURL(recordingBlob);

        setLoadedRecordings([...loadedRecordings, {recordingId: recordingId, recordingUrl: recordingUrl}]);
        setLoadingRecordingIds(loadingRecordingIds.filter((value) => value !== recordingId));
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoadingRecordingIds(loadingRecordingIds.filter((value) => value !== recordingId));
    });
  }

  const formatTime = function (duration) {
    let sec = '' + duration % 60;
    if (sec.length < 2) sec = "0" + sec;
    return Math.floor(duration / 60) + ":" + sec;
  };

  const refreshCallsData = (pageToStart = null, isDownload = false) => {
    if (pageToStart === null) {
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/callHistory/search';

    let params = {};
    for (const key of Object.keys(searchInput)) {
      if ((key === 'start_date' || key === 'end_date')
        && ((searchInput[key] !== null) && (searchInput[key] instanceof Date || typeof searchInput[key] === 'object'))) {
        let date = searchInput[key];

        let day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = '0' + month;
        }

        let hour = date.getHours();
        if (hour < 10) {
          hour = '0' + hour;
        }

        let minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = '0' + minutes;
        }

        let apiDateStringMatches = date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':00';
        params[key] = apiDateStringMatches;
      } else {
        if (searchInput[key] !== '') {
          params[key] = searchInput[key];
        }
      }
    }
    if (!props.line.isMain) params['line_id'] = props.line.id;
    params['start'] = pageToStart * pageSize;
    params['limit'] = pageSize;
    if (isDownload) {
      params['start'] = 0;
      params['limit'] = 999999;
      params['export'] = true;
      fetch(apiUrl, {
        credentials: 'same-origin',
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(params)
      }).then(function (response) {
        setLoading(false);
        if (response['headers'].get('content-type') === 'text/csv; charset=utf-8') return response.blob().then(function (blob) {
          FileSaver.saveAs(blob, `kehilot-reports-${uuidv4()}.csv`);
        });
        return response.json().then(function (data) {
          if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("callList") && data.hasOwnProperty("totalCount"))) {
            setRows(data.callList);
            setRowCount(data.totalCount);
            setLoading(false);
          } else {
            if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        });
      }).catch((e) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
      })
    } else {
      axios.post(apiUrl, params, { headers })
        .then(
          (response) => {
            const data = response.data;
            if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("callList") && data.hasOwnProperty("totalCount"))) {
              setRows(data.callList);
              setRowCount(data.totalCount);
              setLoading(false);
            } else {
              if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
                errorMessage = data["message"];
              }
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
              setLoading(false);
            }
          }).catch((error) => {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          });
    }
  };


  const changeSearchFilters = (e, fieldName, type = null) => {
    let newSearchInput = {
      start_date: searchInput.start_date,
      end_date: searchInput.end_date,
      call_type: searchInput.call_type,
      dial: searchInput.dial,
      duration: searchInput.duration
    };
    if (type === 'date') {
      newSearchInput[fieldName] = e;
    } else {
      newSearchInput[fieldName] = e.target.value;
    }

    setSearchInput(newSearchInput);
  };


  const handleSearchSubmit = (e) => {
    refreshCallsData(0);
  };

  const exportSearchToFile = (e) => {
    refreshCallsData(0, true);
  };

  return (
    <Container style={{ height: 400 }} className="content-wrapper">
      <>
        <Box sx={{ border: 1, pr: 2, pl: 2, mb: 2, borderColor: 'grey.300', borderRadius: '1%' }}>
          <Box sx={{ mt: 2 }}>
            {props.line.isMain ?
              <div className={"notice-message"} id="liability_message">
                <div style={{ marginBottom: '2px' }}>
                  {t("You are viewing the call details of all subscribers. You can view the call details of a certain subscriber under the Manage numbers tab")}
                </div>
              </div>
              : ''}
          </Box>
          <Box sx={{ mb: 2 }}>
            {!props.line.isMain && props.line.use_name !== null ?
              <Typography sx={{ mb: 1 }}><strong>{t('Line user name')}:</strong> {props.line.use_name}</Typography>
              : ''}
          </Box>
          <Box sx={{ mb: 2 }}>
            {!props.line.isMain && props.line.number !== null ?
              <Typography sx={{ mb: 1 }}><strong>{t('Line number')}:</strong> {props.line.number}</Typography>
              : ''}
          </Box>
          <Box sx={{ border: 1, pr: 2, pl: 2, mb: 2, borderColor: 'grey.300', borderRadius: '1%' }}>
            <Box>
              <Typography variant="h6">{t("Search box")}</Typography>
            </Box>
            <Stack spacing={1}
              direction="row"
              flexWrap="wrap"
              sx={[
                {
                  mb: 2,
                  flexWrap: "wrap"
                }
              ]}
            >
              <Box sx={{ ml: 2 }}>
                <Typography>{t('Start Time')}</Typography>
                <DatePicker
                  showTimeSelect
                  selected={searchInput.start_date}
                  onChange={(date) => changeSearchFilters(date, "start_date", 'date')}
                  locale="he"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                  timeFormat="hh:mm:ss"
                />
              </Box>
              <Box sx={{ ml: 2 }}>
                <Typography>{t('End Time')}</Typography>
                <DatePicker
                  showTimeSelect
                  selected={searchInput.end_date}
                  onChange={(date) => changeSearchFilters(date, "end_date", 'date')}
                  locale="he"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                  timeFormat="hh:mm:ss"
                />
              </Box>
              <Box direction="row" sx={{ ml: 2 }}>
                <Typography>{t('Call Type')}</Typography>
                <Select
                  size="small"
                  value={searchInput.call_type}
                  onChange={(e) => changeSearchFilters(e, "call_type")}
                  id="search-status"
                  sx={{
                    width: {
                      lg: "210px"
                    }
                  }}
                >
                  <MenuItem value={CALL_TYPE_ALL}>{t("All")}</MenuItem>
                  <MenuItem value={CALL_TYPE_INCOMING}>{t("Incoming")}</MenuItem>
                  <MenuItem value={CALL_TYPE_OUTCOMING}>{t("Outcoming")}</MenuItem>
                </Select>
              </Box>
              <Box sx={{ ml: 2 }}>
                <Typography>{t('Number')}</Typography>
                <TextField size="small" id="search-dial" value={searchInput.number} onChange={(e) => changeSearchFilters(e, "dial")} />
              </Box>
              <Box sx={{ ml: 2 }}>
                <Typography>{t('Call Duration')}</Typography>
                <TextField size="small" id="search-duration" value={searchInput.number} onChange={(e) => changeSearchFilters(e, "duration")} />
              </Box>
              <Box direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleSearchSubmit(e)}
                  sx={{ width: 210, mt: 3 }}
                >
                  {t('Search')}
                </Button>
              </Box>
              <Box direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => exportSearchToFile(e)}
                  sx={{ width: 210, mt: 3 }}
                >
                  {t('Export To File')}
                </Button>
              </Box>
            </Stack>
          </Box>
          <div style={{ height: 400 }}>
            <DataGrid
              disableSelectionOnClick={true}
              rowCount={rowCount}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              loading={loading}
              getRowId={(row) => row.callId}
            />
          </div>
          <div style={{ height: "20px" }}></div>
        </Box>
      </>
    </Container>
  );
}
