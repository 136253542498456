import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'; 
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function ButtonAppBar() {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const user = useSelector((state) => state.user.value);
  

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="navbar">
          <img className="logo" src="logo192.png" />
        {user.isLoggedIn ?
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              className="navbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
                <MenuItem key="lines-management" onClick={handleCloseNavMenu}>
                <Link to="/lines-management" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('Lines Management')}
                  </Typography>
                </Link>
                </MenuItem>
                <MenuItem key="calls-history" onClick={handleCloseNavMenu}>
                <Link to="/calls-history" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('Lines Calls History')}
                  </Typography>
                </Link>
                </MenuItem>
                <MenuItem key="blocks-management" onClick={handleCloseNavMenu}>
                <Link to="/blocks-management" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('Blocks Management')}
                  </Typography>
                </Link>
                </MenuItem>
                <MenuItem key="watch-system" onClick={handleCloseNavMenu}>
                <Link to="/watch-system" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('Watch System')}
                  </Typography>
                </Link>
                </MenuItem>
                <MenuItem key="user-account" onClick={handleCloseNavMenu}>
                <Link to="/user-account" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('User Account')}
                  </Typography>
                </Link>
                </MenuItem>
                <MenuItem key="logout" onClick={handleCloseNavMenu}>
                <Link to="/logout" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary' }}>
                  {t('Logout')}
                  </Typography>
                </Link>
                </MenuItem>
            </Menu>
          </Box>
          :''}
          {user.isLoggedIn ?
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Link to="/lines-management" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('Lines Management')}
                  </Typography>
                </Link>
                <Link to="/calls-history" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('Lines Calls History')}
                  </Typography>
                </Link>
                <Link to="/blocks-management" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('Blocks Management')}
                  </Typography>
                </Link>
                <Link to="/watch-system" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('Watch System')}
                  </Typography>
                </Link>
                <Link to="/user-account" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('User Account')}
                  </Typography>
                </Link>
                <Link to="/logout" style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'primary.contrastText' }}>
                  {t('Logout')}
                  </Typography>
                </Link>
            </Box>
          : ''}
        </Toolbar>
      </Container>
    </AppBar>
  );
}