import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function Subscription(props) {
    const { t } = useTranslation();

    return (
        <Box sx={{ border: 1, borderColor: "lightgray", p: 2 }}>
            {
                props.isMain === true || props.subscription.default_callerid === 1 ?
                    <Box sx={{ display: "flex", width: "fit-content", m: "auto", mb: 1 }}>
                        {
                            props.isMain === true ?
                                <Typography sx={{
                                    padding: 1,
                                    ml: 1,
                                    backgroundColor: "#0077ff",
                                    color: "white",
                                    fontSize: "15px",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}>{t('Main line')}</Typography> :
                                ''
                        }
                        {
                            props.subscription.default_callerid === 1 ?
                                <Typography sx={{
                                    padding: 1,
                                    ml: 1,
                                    backgroundColor: "#14a522",
                                    color: "white",
                                    fontSize: "15px",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}>{t('Default caller ID')}</Typography> :
                                ''
                        }
                    </Box> :
                    ''
            }
            <Box><Typography sx={{ mb: 1, fontSize: 20 }}><strong>{props.subscription.number}</strong></Typography></Box>
            <Box className='details-rows' sx={{ display: "flex", justifyContent: "space-between" }}>
                {
                    props.isMain === true ?
                        <Box>
                            <Typography sx={{ mb: 1 }}><strong>{t('Line ID')}:</strong>{props.subscription.id}</Typography>
                            <Typography sx={{ mb: 1 }}><strong>{t('Main Package ID')}:</strong>{props.subscription.active_package}</Typography>
                        </Box> :
                        <Box>
                            <Typography sx={{ mb: 1 }}><strong>{t('Package ID')}:</strong>{props.subscription.billing_customer_package_id}</Typography>
                            <Typography sx={{ mb: 1 }}><strong>{t('Country Code')}:</strong>{props.subscription.country_code}</Typography>
                        </Box>
                }
                <Box>
                    <Typography sx={{ mb: 1 }}><strong>{t('Sim ID')}:</strong>{props.subscription.sim_id}</Typography>
                </Box>
            </Box>
            {props.subscription.sim_id ?
                <Box sx={{ border: 2, borderLeft: 10, borderColor: 'grey.500', borderRadius: '5px' }}>
                    <Typography sx={{ margin: 0.3, fontSize: 15 }}><strong>{t('Sim Info')}:</strong></Typography>
                    <Typography sx={{ margin: 1, fontSize: 13 }}><strong>{t('Sim ICCID')}: </strong>{props.subscription.sim_data.ICCID}</Typography>
                    <Typography sx={{ margin: 1, fontSize: 13 }}><strong>{t('Sim PIN1')}: </strong>{props.subscription.sim_data.PIN1}</Typography>
                    <Typography sx={{ margin: 1, fontSize: 13 }}><strong>{t('Sim PUK1')}: </strong>{props.subscription.sim_data.PUK1}</Typography>
                    <Typography sx={{ margin: 1, fontSize: 13 }}><strong>{t('Sim PIN2')}: </strong>{props.subscription.sim_data.PIN2}</Typography>
                    <Typography sx={{ margin: 1, fontSize: 13 }}><strong>{t('Sim PUK2')}: </strong>{props.subscription.sim_data.PUK2}</Typography>
                </Box>
                : ''}
            <Box><Typography sx={{ mb: 1, mt: 2, textAlign: "center" }}>{t('Created at')} <strong>{props.subscription.create_date}</strong></Typography></Box>
        </Box >
    );
}