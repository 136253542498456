import React, { useState, useEffect } from 'react';
import { Box, Collapse} from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../styles.css';

export default function Profile(props) {
  const { t } = useTranslation();

  let initialProfile = {};
  for(let dayIndex=0; dayIndex<7; dayIndex++){
    initialProfile[''+dayIndex] = {};
    for(let hourIndex=0; hourIndex<24; hourIndex++){
      initialProfile[''+dayIndex][''+hourIndex] = {};
      initialProfile[''+dayIndex][''+hourIndex]['status'] = props.defaultStatusValue;
      if(props.hasCallsValue){
        initialProfile[''+dayIndex][''+hourIndex]['calls'] = props.defaultCallsValue;
      }
    }
  }

  let initialCollapsedValue = {};
  for(let dayIndex = 0; dayIndex < 7; dayIndex++){
    initialCollapsedValue['' + dayIndex] = false;
  }
  const [collapsed, setCollapsed] = useState(initialCollapsedValue);

  const dayNumberToTitle = {
    "0": t("Sunday"),
    "1": t("Monday"),
    "2": t("Tuesday"),
    "3": t("Wednesday"),
    "4": t("Thursday"),
    "5": t("Friday"),
    "6": t("Saturday")
  };
    

  useEffect(() => {
    props.setLoaderVisible(false);
  }, [collapsed]);


  const formatHour = (hourIndex) => {
    if(hourIndex < 10){
      return '0'+hourIndex+':00';
    }else{
      return hourIndex+':00';
    }
  }

  const changeProfile = (e, id, modifiedDayIndex, modifiedHourIndex) => {
    let newProfile = Object.assign({}, props.profile);
    newProfile[modifiedDayIndex][modifiedHourIndex][id] = e.target.value;
    props.setProfile(newProfile);
  }

  const hanldeDayTitleClick = (dayIndex) => {
    props.setLoaderVisible(true);
    let newCollapsed = Object.assign({}, collapsed);
    if(collapsed[dayIndex] === false){
      newCollapsed[dayIndex] = true;
    }else{
      newCollapsed[dayIndex] = false;
    }
    setCollapsed(newCollapsed);
  }

  const getCallsLines = () => {
    let callLines = [];
    for (let key in props.callsValues) {
      callLines.push(<option key={key} value={key}>{props.callsValues[key]}</option>);
    }
    return callLines;
  }

  const getStatusLines = () => {
    let statusLines = [];
    for (let key in props.statusValues) {
      statusLines.push(<option key={key} value={key}>{props.statusValues[key]}</option>);
    }
    return statusLines;
  }

  const getDayLines = (dayIndex) => {
    let dayLines = [];
    for(let hourIndex = 0; hourIndex < 24; hourIndex++){
      dayLines.push(
      <Box key={'wrapper-day-hour-'+dayIndex+'-'+hourIndex} 
      sx={[
        {
          ml: 1,
          mb: 1,
          width: '130px'
      }]}>
        <div>{formatHour(hourIndex)}</div>
        {
          (
            props.hasCallsValue ?
            <div>
              <select
                value={props.profile == null ? props.defaultCallsValue : props.profile[dayIndex][hourIndex]['calls']}
                onChange={(e) => changeProfile(e, 'calls', dayIndex, hourIndex)}
                id="calls"
              >
                {getCallsLines()}
              </select>
            </div>
            : ''
          )
        }
        
        <div>
        <select
            value={props.profile == null ? props.defaultStatusValue : props.profile[dayIndex][hourIndex]['status']}
            onChange={(e) => changeProfile(e, 'status', dayIndex, hourIndex)}
            id="status"
          >
            {getStatusLines()}
          </select>
        </div>
      </Box>);
    }
    return dayLines;
  }


  const getLines = () => {
    let lines = [];
    for(let dayIndex = 0; dayIndex < 7; dayIndex++){
      lines.push(
      <Box key={'wrapper-day-'+dayIndex} className='day-wrapper'>
        <Box display="flex" alignItems="center" className='day-title' onClick={() => hanldeDayTitleClick(''+dayIndex)}>
          <div className="day-name">{t(dayNumberToTitle[''+dayIndex])}</div>
          <div className={'arrow '+(collapsed[''+dayIndex] === true ? "arrow-up" : "arrow-down")}></div>
        </Box>
        <Collapse in={collapsed[dayIndex]} collapsedSize={0}>
        <Box display="flex" flexWrap="wrap">
          {getDayLines(dayIndex)}
        </Box>
        </Collapse>
    </Box>);
    }
    return lines;
  }


    return (
        <div className='days-wrapper'>
        {getLines()}
        </div>
    );
}
