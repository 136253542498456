import React, {useState} from 'react';
import { Button, Container, Backdrop, CircularProgress} from '@mui/material';
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, PASSWORD_CALL_AUTH_STATUS_ON, APP_NAME } from '../../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logout } from "../../features/user";
import {handleApiErrorMessage} from "../../Helper";
import Password from "./Password";
import Consent from "../Consent";
import { showErrorDialog } from "../../features/errorDialog";


export default function SignUp(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [termsApproved, setTermsApproved] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [missingRequiredFields, setMissingRequiredFields] = useState({
    password: false,
    consent: false
  });

  const changePassword = (newPassword) => {
    setPassword(newPassword);
  };

  const handleSubmit = () => {
    
    setMissingRequiredFields({
      password: (password === ""),
      consent: (!termsApproved)
    });

    let missingFields = (password === "" || !termsApproved);
    if(missingFields === true){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let params = {
      status: PASSWORD_CALL_AUTH_STATUS_ON,
      password: password
    };
    let apiUrl = API_URL + '/incomingCallsPassword/'+props.lineId+'/registration';

    setLoaderVisible(true);

    axios.post(apiUrl, 
      params, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
            setLoaderVisible(false);
            props.closeDialog();
            props.displayPasswordApprovalScreen();
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoaderVisible(false);
      });
  };

  return (  
    <Container className="content-wrapper sign-up-to-service">
        <div className='terms'>
          <div>{t("Dear customer, you are not registered to the password service")}</div>
          <div className='title'>{t("Registration to the service:")}</div>
          <div>{t("The service allows filtering the callers using a password.")}</div>
          <ul>
            <li>{t("You can control the hours of the service from within your account")}</li>
            <li>{t("You can define numbers that will be exempted from the password obligation")}</li>
            <li>{t("To remove the service or stop the subscription please do that via your customer account. Service removal will delete all your settings.")}</li>
            <li className='strong'>{t("The service is free for the first 12 months. Afterwards, the service will cost 9.9 NIS per month. The usage is according to the {{company}} terms and conditions.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</li>
          </ul>
          <Consent label={t("I approve that I understood the terms and the costs of the service and is interested to sign up.")}
            approved={termsApproved}
            missingRequiredFields={missingRequiredFields}
            changeApproved={setTermsApproved}
             />
        </div>
        <Password password={password} missingRequiredFields={missingRequiredFields} changePassword={changePassword} />
        <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit()}
            className="agree"
        >
            {t('Agree and register to the service')}
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}
