import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {TextField, Button, Select, MenuItem, FormControl, InputLabel, Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {handleApiErrorMessage} from "../Helper";
import { logout } from "../features/user";
import { BLOCK_LIST_MODE_INHERIT, BLOCK_LIST_MODE_WHITELIST, BLOCK_LIST_MODE_BLACKLIST, DEFAULT_ERROR_TEXT } from '../Constants';
import { API_OK, API_EXCEPTION, API_URL } from '../Constants';
import cookie from "js-cookie";
import axios from "axios";
import { showErrorDialog } from "../features/errorDialog";

export default function EditLineInfo(props) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const emptyLineDetails = {
        id: 0,
        use_name: "",
        group_id: "",
        block_list_mode: {
            incoming: BLOCK_LIST_MODE_INHERIT,
            outcoming: BLOCK_LIST_MODE_INHERIT
        }
    }
    const [lineDetails, setLineDetails] = useState(emptyLineDetails);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const defaultSuccessMessage = t("The operation was successfull");
    const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

    const [errorText, setErrorText] = useState({
        group_id : "",
        use_name: ""
    });

    useEffect(() => {
        if(typeof props.line.id === 'undefined'){
            return () => {};
        }
        let initialLineDetails = {
            id: props.line.id,
            use_name: props.line.use_name,
            group_id: props.line.group_id,
            block_list_mode: {
                incoming: (props.line.hasOwnProperty("block_list_mode") ? props.line.block_list_mode.incoming : BLOCK_LIST_MODE_INHERIT),
                outcoming: (props.line.hasOwnProperty("block_list_mode") ? props.line.block_list_mode.outcoming : BLOCK_LIST_MODE_INHERIT)
            }
        };
        setLineDetails(initialLineDetails);
      }, [props.line.id]);

    const displaySuccessMessage = (successMessage = null) => {
        if(successMessage != null){
          setSuccessMessage(successMessage);
        }else{
          setSuccessMessage(defaultSuccessMessage);
        }
        
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      };

    const saveLineData = () => {
        let errorMessage = t(DEFAULT_ERROR_TEXT);
    
        let  headers = {
          token: cookie.get('token')
        }

        let currentLineDetails = JSON.parse(JSON.stringify(lineDetails));
        delete currentLineDetails.id;
        if(currentLineDetails.group_id === ""){
            currentLineDetails.group_id = null;
        }
    
        let apiUrl = API_URL + '/lines/editLine';
        let params = {
            line_id: props.line.id,
            line_obj: currentLineDetails
        };
    
        axios.put(apiUrl, params, {headers})
        .then(
          async (responseData) => {
            if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
              displaySuccessMessage();
            }else{
              if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
                errorMessage = responseData.data["message"];
              }
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            }
          }).catch((error) => {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        });
      };

    const changeLineValue = (e, id='', isRequired='', type='') => {
        let value = e.target.value;
        let currentErrorText = JSON.parse(JSON.stringify(errorText));

        currentErrorText[id] = "";
        setErrorText(currentErrorText);

        if(isRequired === true && value === ''){
            currentErrorText[id] = t("The value is required");
            setErrorText(currentErrorText);
            return;
        }

        if(type === "number"){
            if(isNaN(value)){
                currentErrorText[id] = t("The value has to be a number");
                setErrorText(currentErrorText);
                return;
            }
        }
        

        let targetId = (id === "" ? e.target.id : id);
    
        let newFormInput = {
            id: lineDetails.id,
            use_name: lineDetails.use_name,
            group_id: lineDetails.group_id,
            block_list_mode: {
                incoming: lineDetails.block_list_mode.incoming,
                outcoming: lineDetails.block_list_mode.outcoming
            }
        };

        let blockListPrefixIndex = targetId.indexOf("block_list_mode");
        if(blockListPrefixIndex === -1){
            newFormInput[targetId] = value;
        }else{
            let prefixEndIndex = blockListPrefixIndex + "block_list_mode".length+1;
            if(targetId.substring(prefixEndIndex, prefixEndIndex+2) === "in"){
                newFormInput.block_list_mode.incoming = value;
            }else{
                newFormInput.block_list_mode.outcoming = value;
            }
        }
        setLineDetails(newFormInput);
    }

    return (
        <>
        <div className={"success-message"+ (!showSuccessMessage ? " hidden": "")}>
            <Typography>
            {successMessage}
            </Typography>
        </div>
        <Box sx={{border: 1, borderColor: "lightgray", p: 2}} className="edit-line-wrapper">
            <Box sx={{ display: "flex", alignItems: "center"}} className="field-wrapper">
                <Typography><strong>{t('User Name')}</strong></Typography>
                <TextField
                        autoFocus
                        margin="dense"
                        value={lineDetails.use_name === null ? '' : lineDetails.use_name}
                        id="use_name"
                        label={t("User Name")}
                        fullWidth
                        variant="standard"
                        required
                        onChange={e => changeLineValue(e, "use_name", true)}
                        error={errorText.use_name !== ""}
                        helperText={errorText.use_name}
                    />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center"}} className="field-wrapper">
                <Typography><strong>{t('Group ID')}</strong></Typography>
                <TextField
                        autoFocus
                        margin="dense"
                        value={lineDetails.group_id === null ? '' : lineDetails.group_id}
                        id="group_id"
                        label={t("Group ID")}
                        fullWidth
                        variant="standard"
                        onChange={e => changeLineValue(e, "group_id", false, "number")}
                        error={errorText.group_id !== ""}
                        helperText={errorText.group_id}
                    />
            </Box>
            <Box className="field-wrapper">
                <Typography><strong>{t('Block List Mode')}</strong></Typography>
                <Box sx={{ display: "flex"}} className="field-wrapper">
                    <Typography><strong>{t('Incoming Calls')}:</strong></Typography>
                    <FormControl>
                      <InputLabel>{t("Incoming Calls")}</InputLabel>
                      <Select
                        value={lineDetails.block_list_mode.incoming}
                        onChange={e => changeLineValue(e, 'block_list_mode_incoming')}
                        id="block_list_mode_incoming"
                        label={t("Incoming Calls")}
                      >
                        <MenuItem value={BLOCK_LIST_MODE_INHERIT}>{t(BLOCK_LIST_MODE_INHERIT)}</MenuItem>
                        <MenuItem value={BLOCK_LIST_MODE_BLACKLIST}>{t(BLOCK_LIST_MODE_BLACKLIST)}</MenuItem>
                        <MenuItem value={BLOCK_LIST_MODE_WHITELIST}>{t(BLOCK_LIST_MODE_WHITELIST)}</MenuItem>
                      </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: "flex"}} className="field-wrapper">
                    <Typography><strong>{t('Outcoming Calls')}:</strong></Typography>
                    <FormControl>
                      <InputLabel>{t("Outcoming Calls")}</InputLabel>
                      <Select
                        value={lineDetails.block_list_mode.outcoming}
                        onChange={e => changeLineValue(e, 'block_list_mode_outcoming')}
                        id="block_list_mode_outcoming"
                        label={t("Outcoming Calls")}
                      >
                        <MenuItem value={BLOCK_LIST_MODE_INHERIT}>{t(BLOCK_LIST_MODE_INHERIT)}</MenuItem>
                        <MenuItem value={BLOCK_LIST_MODE_BLACKLIST}>{t(BLOCK_LIST_MODE_BLACKLIST)}</MenuItem>
                        <MenuItem value={BLOCK_LIST_MODE_WHITELIST}>{t(BLOCK_LIST_MODE_WHITELIST)}</MenuItem>
                      </Select>
                    </FormControl>
                </Box>
            </Box>
            <Button
                variant="contained"
                color="primary"
                onClick={(e) => saveLineData(e)}
                sx={{width: 210, mt: 3}}
            >
                {t('Save')}
            </Button>
        </Box>
        </>
    );
}