import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../features/user";
import axios from "axios";
import { API_URL, API_OK, ACTION_ADD, ACTION_EDIT, CALL_TYPE_IN, CALL_TYPE_OUT, STATUS_BLOCKED, STATUS_OPEN, API_EXCEPTION, DEFAULT_ERROR_TEXT, ALL, CALL_TYPE_ALL } from '../Constants';
import { FormControlLabel, Backdrop, CircularProgress, Typography, Container } from '@mui/material';
import { Stack, Button, Select, MenuItem, FormControl, InputLabel, Box, Dialog } from '@mui/material';
import { DataGrid, heIL } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import '../styles.css';
import cookie from "js-cookie";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { showErrorDialog } from "../features/errorDialog";
import { handleApiErrorMessage } from "../Helper";


export default function Category(props) {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [nameErrorText, setNameErrorText] = useState("");

  const initalSelectedCategoryValue = {
    id: null,
    name: null,
    note: null,
    public: 1,
    privacy_level: 0,
    isOwner: 1,
    myJoin: 0,
    valid: 0,
    active_setting: 0
  };
  const [selectedCategory, setSelectedCategory] = useState(initalSelectedCategoryValue);
  const [selectedLineId, setSelectedLineId] = useState(props.lineId);

  const initialLineFormInput = {
    title: t("Add new number"),
    id: null,
    number: "",
    active: 0,
    note: "",
    action: ACTION_ADD,
    call_type: CALL_TYPE_OUT,
    status: STATUS_BLOCKED
  };
  const [lineFormInput, setLineFormInput] = useState(initialLineFormInput);

  const initialSearchInput = {
    number: "",
    active: ALL,
    call_type: ALL,
    status: ALL
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [selectionIds, setSelectionIds] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const defaultSuccessMessage = t("The operation was successfull");

  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: 'number', headerName: t('Number'), width: 130, flex: 1 },
    {
      field: 'active', type: 'boolean', headerName: t('Is Active'), width: 130, flex: 1, sortable: false
    },
    {
      field: 'status', headerName: t('Status'), width: 130,
      valueGetter: (params) => { return (params.row.status === STATUS_BLOCKED ? t("Blocked") : t("Open")) },
      flex: 1, sortable: false
    },
    {
      field: 'call_type', headerName: t('Call Type'), width: 130,
      valueGetter: (params) => {
        let label = null;
        if (params.row.call_type === CALL_TYPE_OUT) {
          label = t("Outgoing");
        }
        if (params.row.call_type === CALL_TYPE_IN) {
          label = t("Incoming");
        }
        if (params.row.call_type === CALL_TYPE_ALL) {
          label = t("Both");
        }
        return label;
      },
      flex: 1, sortable: false
    },
    { field: 'note', headerName: t('Note'), width: 330, flex: 1, filterable: false, sortable: false }
  ];

  if (selectedCategory.isOwner) {
    columns.push(
      {
        field: 'edit', headerName: t('Edit'), width: 330, flex: 1, sortable: false, filterable: false,
        renderCell: (cellValues) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleEditRow(e, cellValues)}
            >
              {t('Edit')}
            </Button>
          );
        }
      }
    );
  }

  useEffect(() => {
    if (loading) {
      return () => { };
    }

    if (props.hasOwnProperty("category")
      && props.category.hasOwnProperty("id")
      && Number.isInteger(props.category.id)) {
      const selectedCategoryValueFromProps = {
        id: props.category.id,
        name: props.category.name,
        note: props.category.note,
        public: props.category.public,
        isOwner: props.category.isOwner,
        myJoin: props.category.myJoin,
        valid: props.category.valid,
        privacy_level: props.category.privacy_level
      };

      setSelectedCategory(selectedCategoryValueFromProps);
    }

  }, []);

  useEffect(() => {
    if (loading) {
      return () => { };
    }
    if (!Number.isInteger(selectedCategory.id) && !Number.isInteger(selectedLineId)) {
      return () => { };
    }
    const requestSource = refreshBlockedData();
    return () => {
      requestSource.cancel();
    };
  }, [page, pageSize]);

  useEffect(() => {
    if (!Number.isInteger(selectedCategory.id) && !Number.isInteger(selectedLineId)) {
      return () => { };
    }
    const requestSource = refreshBlockedData();
    return () => {
      requestSource.cancel();
    };
  }, [selectedCategory.id]);

  const refreshBlockedData = (pageToStart = null) => {
    if (pageToStart === null) {
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/blockList/my/filter';
    if (Number.isInteger(selectedCategory.id)
      && (selectedCategory.id > 0)) {
      apiUrl = API_URL + '/blockList/categories/' + selectedCategory.id + '/filter';
    } else {
      if (Number.isInteger(selectedLineId)
        && (selectedLineId > 0)) {
        apiUrl = API_URL + '/blockList/by_line/' + selectedLineId + '/filter';
      }
    }

    const requestSource = axios.CancelToken.source();
    headers.cancelToken = requestSource.token;

    axios.post(
      apiUrl,
      { searchStr: searchInput.number, active: searchInput.active === ALL ? ALL : Number(searchInput.active), status: searchInput.status, call_type: searchInput.call_type, start: pageToStart * pageSize, limit: pageSize },
      { headers })
      .then(
        ({ data }) => {
          if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("results") && data.hasOwnProperty("totalBlocks"))) {
            setRows(data.results);
            setRowCount(data.totalBlocks);
            setLoading(false);
          } else {
            if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        });


    return requestSource;
  };


  const handleEditRow = (e, cellValues) => {
    let changedIndex = getLineDataIndexById(cellValues.id);

    if (changedIndex === -1) {
      return;
    }

    const lineData = {
      title: t('Edit number'),
      id: rows[changedIndex]['id'],
      active: rows[changedIndex]['active'],
      number: rows[changedIndex]['number'],
      call_type: rows[changedIndex]['call_type'],
      status: rows[changedIndex]['status'],
      note: rows[changedIndex]['note'],
      action: ACTION_EDIT
    };
    setLineFormInput(lineData);
    setDialogIsOpen(true);
  };

  const handleAddRow = () => {
    setDialogIsOpen(true);
  };


  const handleDeleteRows = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    if (!selectionIds) {
      alert(t('No lines selected'));
      return;
    } else {
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if (nonRemovedRows.length === rows.length) {
        alert(t('No lines selected'));
        return;
      }
    }

    setDialogLoaderVisible(true);

    let headers = {
      token: cookie.get('token')
    }

    const data = {
      blockIds: selectionIds
    };

    let apiUrl = API_URL + '/blockList/my/delete';
    if (Number.isInteger(selectedCategory.id)
      && (selectedCategory.id > 0)) {
      apiUrl = API_URL + '/blockList/categories/' + selectedCategory.id + '/deleteBlocks';
    } else {
      if (Number.isInteger(selectedLineId)
        && (selectedLineId > 0)) {
        apiUrl = API_URL + '/blockList/by_line/' + selectedLineId + '/deleteBlocks';
      }
    }

    axios.post(apiUrl, data, { headers })
      .then(
        (responseData) => {
          if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
            refreshBlockedData();
          } else {
            if (responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")) {
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
          resetDialogHideLoader();
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);

          resetDialogHideLoader();
        });
  };

  const getLineDataIndexById = (lineDataId) => {
    let lineDataIndex = -1;
    for (let index = 0; index < rows.length; index++) {
      const lineData = rows[index];
      if (lineData.id === lineDataId) {
        lineDataIndex = index;
        break;
      }
    }
    return lineDataIndex;
  };

  const resetDialogHideLoader = () => {
    setDialogLoaderVisible(false);
    setDialogIsOpen(false);
    setLineFormInput(initialLineFormInput);
  };

  const displaySuccessMessage = (successMessage = null) => {
    if (successMessage != null) {
      setSuccessMessage(successMessage);
    } else {
      setSuccessMessage(defaultSuccessMessage);
    }

    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 2000);
  };

  const addLinesData = (linesDataToAdd) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/blockList/my/add';
    if (Number.isInteger(selectedCategory.id)
      && (selectedCategory.id > 0)) {
      apiUrl = API_URL + '/blockList/categories/' + selectedCategory.id + '/add';
    } else {
      if (Number.isInteger(selectedLineId)
        && (selectedLineId > 0)) {
        apiUrl = API_URL + '/blockList/by_line/' + selectedLineId + '/add';
      }
    }

    axios.post(apiUrl, linesDataToAdd, { headers })
      .then(
        async (responseData) => {
          if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
            await refreshBlockedData();
            resetDialogHideLoader();
            displaySuccessMessage();
            setShowSuccessMessage(true);
          } else {
            if ((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))) {
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setDialogLoaderVisible(false);
        });
  };


  const createCategory = (categoryData) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/blockList/categories/new';

    axios.post(apiUrl, { new_obj: categoryData }, { headers })
      .then(
        async (responseData) => {
          if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK && responseData.data.hasOwnProperty('newCategorieId')) {
            let selectedCategoryData = {
              id: responseData.data.newCategorieId,
              name: selectedCategory.name,
              note: selectedCategory.note,
              public: selectedCategory.public,
              isOwner: selectedCategory.isOwner,
              myJoin: selectedCategory.myJoin
            };
            setSelectedCategory(selectedCategoryData);
            resetDialogHideLoader();
          } else {
            if ((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))) {
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setDialogLoaderVisible(false);
        });
  };

  const handleSubmit = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setDialogLoaderVisible(true);

    let headers = {
      token: cookie.get('token')
    }


    if (lineFormInput.action === ACTION_EDIT) {
      const data = {
        blockId: lineFormInput.id,
        newData: {
          note: lineFormInput.note,
          active: lineFormInput.active,
          call_type: lineFormInput.call_type,
          status: lineFormInput.status
        }
      };

      let apiUrl = API_URL + '/blockList/my/edit';
      if (Number.isInteger(selectedCategory.id)
        && (selectedCategory.id > 0)) {
        apiUrl = API_URL + '/blockList/categories/' + selectedCategory.id + '/edit';
      } else {
        if (Number.isInteger(selectedLineId)
          && (selectedLineId > 0)) {
          apiUrl = API_URL + '/blockList/by_line/' + selectedLineId + '/edit';
        }
      }

      axios.put(apiUrl, data, { headers })
        .then(
          async (responseData) => {
            if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
              await refreshBlockedData();
              displaySuccessMessage();
            } else {
              if (responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")) {
                errorMessage = responseData.data["message"];
              }
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            }
            resetDialogHideLoader();
          }).catch((error) => {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            resetDialogHideLoader();
          });
    } else {

      const data = {
        numbers: [
          {
            number: lineFormInput.number,
            active: lineFormInput.active,
            note: lineFormInput.note,
            call_type: lineFormInput.call_type,
            status: lineFormInput.status
          }
        ]
      };

      addLinesData(data);
    }
  }



  const handleCategoryCreateSubmit = () => {
    setDialogLoaderVisible(true);

    const newCategoryData = {
      name: selectedCategory.name,
      note: selectedCategory.note,
      public: selectedCategory.public,
      privacy_level: selectedCategory.privacy_level
    };

    createCategory(newCategoryData);
  }

  const handleChange = (file) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    const data = new FormData();
    data.append('file', file);

    let headers = {
      token: cookie.get('token')
    }

    setLoading(true);

    let apiUrl = API_URL + '/blockList/my/importExcel';
    if (Number.isInteger(selectedCategory.id)
      && (selectedCategory.id > 0)) {
      apiUrl = API_URL + '/blockList/categories/' + selectedCategory.id + '/importExcel';
    } else {
      if (Number.isInteger(selectedLineId)
        && (selectedLineId > 0)) {
        apiUrl = API_URL + '/blockList/by_line/' + selectedLineId + '/importExcel';
      }
    }

    axios.post(apiUrl, data, { headers })
      .then(
        async (responseData) => {
          if (responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK) {
            await refreshBlockedData();
            let successMessage = null;
            if (responseData.data.hasOwnProperty('results') && responseData.data.results.hasOwnProperty('adds')) {
              successMessage = t("The upload was successful. Added {{added}} rows and updated {{updated}} rows.",
                { added: responseData.data.results.adds, updated: responseData.data.results.updates });
            }
            displaySuccessMessage(successMessage);
            setShowSuccessMessage(true);
            setLoading(false);
          } else {
            if ((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))) {
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        });
  }

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  const changeSearchFilters = (e, fieldName) => {
    let newSearchInput = {
      number: searchInput.number,
      active: searchInput.active,
      call_type: searchInput.call_type,
      status: searchInput.status
    };
    newSearchInput[fieldName] = e.target.value;
    setSearchInput(newSearchInput);
  };

  const handleSearchSubmit = (e) => {
    refreshBlockedData(0);
  };

  const changeLineValue = (e, type = '', id = '') => {
    let value = e.target.value;

    let targetId = (id === "" ? e.target.id : id);

    if (type === "number") {
      if (/[\D]+[*]/.test(value)) {
        setPhoneErrorText("The phone can only contain numbers");
        return;
      }

      setPhoneErrorText("");
    }
    if (targetId === "active") {
      value = (e.target.checked ? 1 : 0);
    }
    let newFormInput = {
      title: lineFormInput.title,
      id: lineFormInput.id,
      number: lineFormInput.number,
      active: lineFormInput.active,
      note: lineFormInput.note,
      action: lineFormInput.action,
      call_type: lineFormInput.call_type,
      status: lineFormInput.status
    };
    newFormInput[targetId] = value;
    setLineFormInput(newFormInput);
  }


  const changeCategoryValue = (e, type = '', id = '', value = '', isNumeric = false) => {
    if (value === '') {
      value = e.target.value;
    }
    if (isNumeric) {
      value = Number.parseInt(value);
    }
    let targetId = (id === "" ? e.target.id : id);

    let privacyLevel = Number.parseInt(selectedCategory.privacy_level);
    if (targetId === "public" && value === 1) {
      privacyLevel = 0;
    }

    if (type === "name") {
      if (value === "") {
        setNameErrorText("The name is required");
        return;
      }

      setNameErrorText("");
    }
    let newFormInput = {
      id: selectedCategory.id,
      name: selectedCategory.name,
      note: selectedCategory.note,
      isOwner: selectedCategory.isOwner,
      public: selectedCategory.public,
      privacy_level: privacyLevel
    };
    newFormInput[targetId] = value;
    setSelectedCategory(newFormInput);
  }

  return (
    <Container style={{ height: ((Number.isInteger(selectedCategory.id)) ? 400 : 'unset') }} className="content-wrapper">
      <>
        {
          Number.isInteger(selectedCategory.id) ?
            <Box sx={{ mb: 2 }}>
              {selectedCategory.isOwner ?
                ''
                :
                <div className={"notice-message"}>
                  <Typography>
                    {t("Please note that you can't make changes in this caategory, because it doesn't belong to you")}
                  </Typography>
                </div>
              }
              {(!selectedCategory.isOwner && selectedCategory.public === 1 && Number.parseInt(selectedCategory.privacy_level) > 0) ?
                <div className={"notice-message"}>
                  <Typography>
                    {t("You can't perform filtering in this category, because the numbers in this category are hidden.")}
                  </Typography>
                </div>
                :
                ''
              }
              {selectedCategory.name !== null ?
                <Typography sx={{ mb: 1 }}><strong>{t('Name')}:</strong> {selectedCategory.name}</Typography>
                : ''}
              {selectedCategory.note !== null ?
                <Typography sx={{ mb: 1 }}><strong>{t('Description')}:</strong> {selectedCategory.note}</Typography>
                : ''}

              {selectedCategory.public !== null ?
                <Typography sx={{ mb: 1 }}><strong>{t('Public')}:</strong> {(selectedCategory.public === 0 ? t('No') : t('Yes'))}</Typography>
                : ''}
              {selectedCategory.valid === 1 ?
                <strong style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <div style={{ marginLeft: "7px" }}>{t('Verified category')}</div>
                  <div className="check-wrapper"><div className="check"></div></div></strong>

                : ''}
            </Box> :
            Number.isInteger(selectedLineId) ?
              '' :
              <Box sx={{ mb: 2, width: '80%' }}>
                <TextField
                  sx={{ mb: 1 }}
                  autoFocus
                  margin="dense"
                  value={(selectedCategory.name !== null ? selectedCategory.name : '')}
                  id="name"
                  label={t("Name")}
                  type="name"
                  fullWidth
                  variant="standard"
                  required
                  onChange={e => changeCategoryValue(e, 'name')}
                  error={nameErrorText !== ""}
                  helperText={nameErrorText}
                  disabled={Number.isInteger(selectedCategory.id) && (selectedCategory.id > 0)}
                />
                <TextField
                  sx={{ mb: 5 }}
                  autoFocus
                  margin="dense"
                  value={(selectedCategory.note !== null ? selectedCategory.note : '')}
                  id="note"
                  label={t("Description")}
                  type="note"
                  fullWidth
                  variant="standard"
                  onChange={e => changeCategoryValue(e, 'note')}
                  disabled={Number.isInteger(selectedCategory.id) && (selectedCategory.id > 0)}
                />
                <FormControl fullWidth
                  sx={{ mb: 1 }}
                >
                  <InputLabel id="status-label">{t("Is Public")}</InputLabel>
                  <Select
                    labelId="status-label"
                    value={'' + selectedCategory.public}
                    onChange={e => changeCategoryValue(e, '', 'public', '', true)}
                    id="public"
                    label={t("Public")}
                    disabled={Number.isInteger(selectedCategory.id) && (selectedCategory.id > 0)}
                  >
                    <MenuItem value="0">{t("No")}</MenuItem>
                    <MenuItem value="1">{t("Yes")}</MenuItem>
                  </Select>
                </FormControl>
                {
                  selectedCategory.public === 1 ?
                    <FormControlLabel style={{ display: "block" }} checked={selectedCategory.privacy_level === 1} control={<Switch id="privacy_level" onChange={e => changeCategoryValue(e, '', 'privacy_level', (e.target.checked ? 1 : 0), true)} />} label={t("Don't allow guests to see the numbers on the list")} />
                    : ''
                }

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleCategoryCreateSubmit(e)}
                  sx={{ width: 210, mt: 3 }}

                >
                  {t('Create')}
                </Button>
              </Box>
        }
        {
          Number.isInteger(selectedCategory.id) || Number.isInteger(selectedLineId) ?
            <>
              <Box sx={{ border: 1, pr: 2, pl: 2, mb: 2, borderColor: 'grey.300', borderRadius: '1%' }}>
                {Number.isInteger(selectedLineId) ?
                  <Typography sx={{ mb: 2, mt: 2 }}></Typography> :
                  <Typography sx={{ mb: 2, mt: 2 }}><strong>{t('Records in category')}:</strong></Typography>
                }
                <Box sx={{ border: 1, pr: 2, pl: 2, mb: 2, borderColor: 'grey.300', borderRadius: '1%' }}>
                  {
                    (!selectedCategory.isOwner
                      && selectedCategory.public === 1
                      && Number.parseInt(selectedCategory.privacy_level) > 0
                      && !Number.isInteger(selectedLineId)
                    ) ?
                      '' :
                      <>
                        <Box>
                          <Typography variant="h6">{t("Search box")}</Typography>
                        </Box>
                        <Stack spacing={1}
                          direction="row"
                          flexWrap="wrap"
                          sx={[
                            {
                              mb: 2,
                              flexWrap: "wrap"
                            }
                          ]}
                        >
                          <Box sx={{ ml: 2 }}>
                            <Typography>{t('Number')}</Typography>
                            <TextField size="small" id="search-number" value={searchInput.number} onChange={(e) => changeSearchFilters(e, "number")} />
                          </Box>
                          <Box direction="row" sx={{ ml: 2 }}>
                            <Typography>{t('Is Active')}</Typography>
                            <Select
                              size="small"
                              value={searchInput.active}
                              onChange={(e) => changeSearchFilters(e, "active")}
                              sx={{
                                width: {
                                  lg: "210px"
                                }
                              }}
                            >
                              <MenuItem value={ALL}>{t("All")}</MenuItem>
                              <MenuItem value="1">{t("Yes")}</MenuItem>
                              <MenuItem value="0">{t("No")}</MenuItem>
                            </Select>
                          </Box>
                          <Box direction="row" sx={{ ml: 2 }}>
                            <Typography>{t('Status')}</Typography>
                            <Select
                              size="small"
                              value={searchInput.status}
                              onChange={(e) => changeSearchFilters(e, "status")}
                              id="search-status"
                              sx={{
                                width: {
                                  lg: "210px"
                                }
                              }}
                            >
                              <MenuItem value={ALL}>{t("All")}</MenuItem>
                              <MenuItem value={STATUS_BLOCKED}>{t("Blocked")}</MenuItem>
                              <MenuItem value={STATUS_OPEN}>{t("Open")}</MenuItem>
                            </Select>
                          </Box>
                          <Box direction="row" sx={{ ml: 2 }}>
                            <Typography>{t('Call Type')}</Typography>
                            <Select
                              size="small"
                              value={searchInput.call_type}
                              onChange={(e) => changeSearchFilters(e, "call_type")}
                              id="search-call-type"
                              sx={{
                                width: {
                                  lg: "210px"
                                }
                              }}
                            >
                              <MenuItem value={ALL}>{t("All")}</MenuItem>
                              <MenuItem value={CALL_TYPE_OUT}>{t("Outgoing call")}</MenuItem>
                              <MenuItem value={CALL_TYPE_IN}>{t("Incoming call")}</MenuItem>
                            </Select>
                          </Box>
                          <Box direction="row">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(e) => handleSearchSubmit(e)}
                              sx={{ width: 210, mt: 3 }}
                            >
                              {t('Search')}
                            </Button>
                          </Box>
                        </Stack>
                      </>
                  }
                </Box>
                {
                  selectedCategory.isOwner ?
                    <Stack direction="row" spacing={1}
                      sx={[
                        {
                          mb: 2
                        }
                      ]}
                    >
                      <Button size="medium" onClick={handleDeleteRows}
                        sx={[
                          {
                            bgcolor: 'red',
                            color: 'white',
                            ml: 2
                          },
                          {
                            '&:hover': {
                              bgcolor: '#ff7961',
                              color: 'white'
                            },
                          }
                        ]}
                      >
                        {t('Delete selected rows')}
                      </Button>
                      <Button size="medium" onClick={handleAddRow}
                        sx={[
                          {
                            bgcolor: 'green',
                            color: 'white'
                          },
                          {
                            '&:hover': {
                              bgcolor: 'lightgreen',
                              color: 'white'
                            },
                          }
                        ]}>
                        {t('Add a row')}
                      </Button>
                    </Stack>
                    : ''
                }
                <div className={"success-message" + (!showSuccessMessage ? " hidden" : "")}>
                  <Typography>
                    {successMessage}
                  </Typography>
                </div>
                <div style={{ height: 400 }}>
                  {(selectedCategory.isOwner ?
                    <DataGrid
                      disableSelectionOnClick={true}
                      rowCount={rowCount}
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      checkboxSelection
                      onSelectionModelChange={(ids) => {
                        setSelectionIds(ids);
                      }}
                      localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                      paginationMode="server"
                      onPageChange={(newPage) => {
                        setPage(newPage);
                      }}
                      loading={loading}
                    />
                    :
                    <DataGrid
                      disableSelectionOnClick={true}
                      rowCount={rowCount}
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      onSelectionModelChange={(ids) => {
                        setSelectionIds(ids);
                      }}
                      localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                      paginationMode="server"
                      onPageChange={(newPage) => {
                        setPage(newPage);
                      }}
                      loading={loading}
                    />
                  )}
                </div>
                {
                  selectedCategory.isOwner ?
                    <Stack sx={{ mb: 3, alignItems: "center", flexWrap: "wrap" }} direction="row">
                      <FileUploader handleChange={handleChange} name="file" types={["xlsx", "xls", "csv"]} classes="file-uploader" label={t('Upload or drop file here')} />
                      <a href="/מספרים לחסימה.xlsx" download style={{ marginRight: "24px", marginTop: "24px" }}>{t("Download import file sample")}</a>
                    </Stack>
                    : ''
                }
                <div style={{ height: "20px" }}></div>
              </Box>
              <Dialog open={dialogIsOpen} onClose={handleDialogClose}
              >
                <DialogTitle>{t(lineFormInput.title)}</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    value={lineFormInput.number}
                    id="number"
                    label={t("Phone Number")}
                    type="string"
                    fullWidth
                    variant="standard"
                    disabled={lineFormInput.action === ACTION_EDIT}
                    required
                    onChange={e => changeLineValue(e, 'number')}
                    error={phoneErrorText !== ""}
                    helperText={phoneErrorText}
                  />
                  <FormControlLabel checked={lineFormInput.active === 1} control={<Switch id="active" onChange={e => changeLineValue(e)} />} label={t("Is Active")} />
                  <FormControl fullWidth
                    sx={[
                      {
                        mb: 2
                      }
                    ]}>
                    <InputLabel id="call-type-label">{t("Call Type")}</InputLabel>
                    <Select
                      labelId="call-type-label"
                      value={lineFormInput.call_type}
                      onChange={e => changeLineValue(e, '', 'call_type')}
                      id="call_type"
                      label={t("Call Type")}
                    >
                      <MenuItem value={CALL_TYPE_OUT}>{t("Outgoing call")}</MenuItem>
                      <MenuItem value={CALL_TYPE_IN}>{t("Incoming call")}</MenuItem>
                      <MenuItem value={CALL_TYPE_ALL}>{t("Both")}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth
                    sx={[
                      {
                        mb: 2
                      }
                    ]}>
                    <InputLabel id="call-type-label">{t("Status")}</InputLabel>
                    <Select
                      labelId="status-label"
                      value={lineFormInput.status}
                      onChange={e => changeLineValue(e, '', 'status')}
                      id="status"
                      label={t("Status")}
                    >
                      <MenuItem value={STATUS_BLOCKED}>{t("Blocked")}</MenuItem>
                      <MenuItem value={STATUS_OPEN}>{t("Open")}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    autoFocus
                    margin="dense"
                    value={lineFormInput.note}
                    id="note"
                    label={t("Note")}
                    type="note"
                    fullWidth
                    variant="standard"
                    onChange={e => changeLineValue(e)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={handleDialogClose} size="large" sx={{ ml: 2 }}>{t('Cancel')}</Button>
                  <Button variant="contained" onClick={handleSubmit} size="large">{t('Submit')}</Button>
                </DialogActions>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={dialogLoaderVisible}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Dialog>
            </>
            : ''
        }
      </>
    </Container>
  );
}
