import React, {useState, useEffect} from 'react';
import { OutlinedInput, InputAdornment, IconButton} from '@mui/material';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";


export default function Password({password, missingRequiredFields ,changePassword}) {
  const { t } = useTranslation();
  let [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = (e) => {
    let numericValue = e.target.value.replace(/\D/g,'');
    changePassword(numericValue);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const passwordElement = document.getElementById("password");
    const passwordError = passwordElement.closest(".password").querySelector(".error");

    if(missingRequiredFields['password'] === true){
        passwordError.classList.remove("hidden");
    }else{
        passwordError.classList.add("hidden");
    }
  }, [missingRequiredFields]);

  return (  
    <div className='password'>
        <div className='wrapper'>
        <div>{t('Chosen password')}</div>
        <div className='small'>{t('Up to 10 digits')}</div>
        </div>
        <div className='wrapper field'>
        <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => handleChangePassword(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        <div className='error hidden'>{t('This field is required')}</div>
        </div>
    </div>
  );
}
