import React from 'react';
import { useTranslation } from 'react-i18next';
import {APP_NAME} from '../../Constants'


export default function SignUp() {
  const { t } = useTranslation();

  return (  
      <div className='description'>
        <div>{t("Anti spam service is a currently free service of {{company}}.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</div>
        <div>{t("The service allows filtering spam.")}</div>
        <div className='strong'>{t("How does it works?")}</div>
        <div>{t("Every phone number has a rank saved in {{company}}, the system uses this rank to decide whether to authenticate the caller.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</div>
        <div>{t("The authentication procedure is smart and will save the results for the future.")}</div>
        <div>{t("There are a few levels of the authentication that makes reliable.")}</div>
        <div className='strong'>{t("Temporary disabling of the service")}</div>
        <div>{t("You can temporarily disable the service")}</div>
        <div>{t("It can be useful to get calls from the bank etc")}</div>
        <div>{t("There are a few ways that you can disable the service:")}</div>
        <ul>
          <li>{t("Via your customer account")}</li>
          <li>{t("By calling 111")}</li>
          <li>{t("Using the short calling")}</li>
        </ul>
        <div>{t("Please note that the time of cancelattion can be overwritten")}</div>
        <div className='strong'>{t("Manual ranking")}</div>
        <div>{t("After the registration you will get access to the ranking interface")}</div>
        <div>{t("The reporting influences the customer in {{company}}", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</div>
        <div className='strong'>{t("Service removal:")}</div>
        <div>{t("Any moment you can remove the service")}</div>
        <div className='strong'>{t("Please note that the {{company}} don't take responisbility on the calls authentication", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</div>
      </div>
  );
}
