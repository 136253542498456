import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import { BLOCK_LIST_MODE_BLACKLIST, BLOCK_LIST_MODE_WHITELIST } from '../../Constants';
import { Button, Typography, TextField, Box, FormControl, InputLabel, Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cookie from "js-cookie";
import {handleApiErrorMessage} from "../../Helper"
import { logout } from "../../features/user";


export function UserAccount() {
    const [userAccountData, setUserAccountData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const defaultSuccessMessage = t("The operation was successfull");
    const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
    const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

    const [errorText, setErrorText] = useState({});
    
    const nonEditableUserAccountFields = [
        //{
        //    'key': 'customerId',
        //    'label': t('Customer ID')
        //},
        {
            'key': 'name',
            'label': t('Name')
        },
        {
            'key': 'active',
            'label': t('Active'),
            'type': 'boolean'
        },
        {
            'key': 'approved',
            'label': t('Approved'),
            'type': 'boolean'
        },
        {
            'key': 'valid_id',
            'label': t('Valid ID')
        },
        {
            'key': 'valid_id_type',
            'label': t('Valid ID type')
        },
        {
            'key': 'create_time',
            'label': t('Created at')
        },
        {
            'key': 'update_time',
            'label': t('Updated at')
        },
        {
            'key': 'billing_balance',
            'label': t('Billing Balance')
        },
        {
            'key': 'billing_day',
            'label': t('Billing Day')
        }
    ];
    const editableUserAccountFields = [
        {
            'key' : 'email',
            'label': t('Email'),
            'required' : true
        },
        {
            'key' : 'phone',
            'label': t('Phone'),
            'type': 'number',
            'required' : true
        },
        {
            'key' : 'address',
            'label': t('Address')
        },
        {
            'key' : 'city',
            'label': t('City')
        },
        {
            'key' : 'country',
            'label': t('Country')
        }
    ];
  
    useEffect(() => {
        let  headers = {
          token: cookie.get('token') 
        }
    
        let errorMessage = t(DEFAULT_ERROR_TEXT);
    
        let apiUrl = API_URL + '/my/';
    
        axios.get(apiUrl, {headers})
          .then(
            ({ data }) => {
              if(data.hasOwnProperty("responseStatus") && (data['responseStatus'] === API_OK)){
                setUserAccountData(data);
              }else{
                if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
                  errorMessage = data["message"];
                }
                handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
              }
            }).catch((error) => {
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          });
      }, []);


    const displaySuccessMessage = (successMessage = null) => {
    if(successMessage != null){
        setSuccessMessage(successMessage);
    }else{
        setSuccessMessage(defaultSuccessMessage);
    }
    
    setShowSuccessMessage(true);
    setTimeout(() => {
        setShowSuccessMessage(false);
    }, 2000);
    };

    const changeValue = (e, id='', isRequired='', type='') => {
        let value = e.target.value;
        let currentErrorText = JSON.parse(JSON.stringify(errorText));

        currentErrorText[id] = "";
        setErrorText(currentErrorText);

        if(isRequired === true && value === ''){
            currentErrorText[id] = t("The value is required");
            setErrorText(currentErrorText);
        }

        if(type === "number"){
            if(isNaN(value)){
                currentErrorText[id] = t("The value has to be a number");
                setErrorText(currentErrorText);
            }
        }
        
        let targetId = (id === "" ? e.target.id : id);
    
        let newFormInput = JSON.parse(JSON.stringify(userAccountData));
        if(!newFormInput.hasOwnProperty('block_list_mode')){
            newFormInput.block_list_mode = {
                incoming: userAccountData.block_list_mode.incoming,
                outcoming: userAccountData.block_list_mode.outcoming
            };
        }

        for (const fieldData of editableUserAccountFields) {
            if(userAccountData.hasOwnProperty(fieldData.key)){
                newFormInput[fieldData.key] = userAccountData[fieldData.key];
            }else{
                if(fieldData.hasOwnProperty('type') && (fieldData['type'] === 'number')){
                    newFormInput[fieldData.key] = 0;
                }else{
                    newFormInput[fieldData.key] = '';
                }
            }
        }

        let blockListPrefixIndex = targetId.indexOf("block_list_mode");
        if(blockListPrefixIndex === -1){
            newFormInput[targetId] = value;
        }else{
            let prefixEndIndex = blockListPrefixIndex + "block_list_mode".length+1;
            if(targetId.substring(prefixEndIndex, prefixEndIndex+2) === "in"){
                newFormInput.block_list_mode.incoming = value;
            }else{
                newFormInput.block_list_mode.outcoming = value;
            }
        }
        setUserAccountData(newFormInput);
    }

    const saveUserAccountData = () => {
        for (const [errorKey, errorValue] of Object.entries(errorText)) {
            if(errorValue !== ''){
                setErrorMessage(t('Please fill in the correct values'));
                setShowErrorMessage(true);
                return;
            }
        }

        let errorMessage = t(DEFAULT_ERROR_TEXT);
    
        let  headers = {
          token: cookie.get('token')
        }

        let params = {};
        for(const index in editableUserAccountFields){
            params[editableUserAccountFields[index].key] = userAccountData[editableUserAccountFields[index].key];
        }
        params.block_list_mode = {
            incoming: userAccountData.block_list_mode.incoming,
            outcoming: userAccountData.block_list_mode.outcoming
        };

        let apiUrl = API_URL + '/my/';

        axios.put(apiUrl, params, {headers})
        .then(
          async (responseData) => {
            if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
              displaySuccessMessage();
            }else{
              if((responseData.data.responseStatus === API_EXCEPTION) && (responseData.data.hasOwnProperty("message"))){
                errorMessage = responseData.data["message"];
              }
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            }
          }).catch((error) => {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        });
      };
    

    return (
        <Container maxWidth="xl" className="content-wrapper">
            <Box className="user-account-wrapper">
                <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
                    <div>{successMessage}</div>
                    <IconButton onClick={() => setShowSuccessMessage(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
                    <div>{errorMessage}</div>
                    <IconButton onClick={() => setShowErrorMessage(false)}>
                    <CloseIcon />
                    </IconButton>
                </Box>
                {
                    editableUserAccountFields.map(function(editableUserAccountField, i){
                    return <Box sx={{ display: "flex", alignItems: "center"}} className="field-wrapper" key={editableUserAccountField.key}>
                                <Typography className="label"><strong>{t(editableUserAccountField.label)}</strong></Typography>
                                <TextField
                                        autoFocus
                                        margin="dense"
                                        value={!userAccountData.hasOwnProperty(editableUserAccountField.key) ? '' : userAccountData[editableUserAccountField.key]}
                                        id={editableUserAccountField.key}
                                        label={t(editableUserAccountField.label)}
                                        fullWidth
                                        variant="standard"
                                        required={editableUserAccountField.hasOwnProperty('required')}
                                        onChange={
                                            e => changeValue(
                                                e, 
                                                editableUserAccountField.key, 
                                                (editableUserAccountField.hasOwnProperty('required') ? true : false),
                                                (editableUserAccountField.hasOwnProperty('type') ? editableUserAccountField.type : null)
                                            )
                                        }
                                        error={errorText.hasOwnProperty(editableUserAccountField.key) && errorText[editableUserAccountField.key] !== ""}
                                        helperText={errorText.hasOwnProperty(editableUserAccountField.key) ? errorText[editableUserAccountField.key] : ""}
                                    />
                            </Box>;
                    })
                }
                    <Box className="field-wrapper block-list" key='block_list_mode'>
                        <Typography className="label main"><strong>{t('Block List Mode')}</strong></Typography>
                        <Box sx={{ display: "flex"}} className="field-wrapper">
                            <Typography className="label intend"><strong>{t('Incoming Calls')}:</strong></Typography>
                            <FormControl>
                            <InputLabel>{t("Incoming Calls")}</InputLabel>
                            <Select
                                value={(userAccountData.hasOwnProperty('block_list_mode') ? userAccountData.block_list_mode.incoming : BLOCK_LIST_MODE_BLACKLIST)}
                                onChange={e => changeValue(e, 'block_list_mode_incoming')}
                                id="block_list_mode_incoming"
                                label={t("Incoming Calls")}
                            >
                                <MenuItem value={BLOCK_LIST_MODE_BLACKLIST}>{t(BLOCK_LIST_MODE_BLACKLIST)}</MenuItem>
                                <MenuItem value={BLOCK_LIST_MODE_WHITELIST}>{t(BLOCK_LIST_MODE_WHITELIST)}</MenuItem>
                            </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex"}} className="field-wrapper">
                            <Typography className="label intend"><strong>{t('Outcoming Calls')}:</strong></Typography>
                            <FormControl>
                            <InputLabel>{t("Outcoming Calls")}</InputLabel>
                            <Select
                                value={(userAccountData.hasOwnProperty('block_list_mode') ? userAccountData.block_list_mode.outcoming : BLOCK_LIST_MODE_BLACKLIST)}
                                onChange={e => changeValue(e, 'block_list_mode_outcoming')}
                                id="block_list_mode_outcoming"
                                label={t("Outcoming Calls")}
                            >
                                <MenuItem value={BLOCK_LIST_MODE_BLACKLIST}>{t(BLOCK_LIST_MODE_BLACKLIST)}</MenuItem>
                                <MenuItem value={BLOCK_LIST_MODE_WHITELIST}>{t(BLOCK_LIST_MODE_WHITELIST)}</MenuItem>
                            </Select>
                            </FormControl>
                        </Box>
                    </Box>
                {
                    nonEditableUserAccountFields.map(function(nonEditableUserAccountField, i){
                    return <Box sx={{ display: "flex", alignItems: "center"}} className="field-wrapper" key={nonEditableUserAccountField.key}>
                                <Typography className="label"><strong>{t(nonEditableUserAccountField.label)}:</strong></Typography>
                                <Typography>{(nonEditableUserAccountField.hasOwnProperty('type') && nonEditableUserAccountField.type === "boolean") ?
                                            (userAccountData[nonEditableUserAccountField.key] === 1 ? t("Yes") : t("No")) :
                                            t(userAccountData[nonEditableUserAccountField.key])
                                            }</Typography>
                            </Box>;
                    })
                }
            <Button
                variant="contained"
                color="primary"
                onClick={(e) => saveUserAccountData(e)}
                sx={{width: 210, mt: 3}}
            >
                {t('Save')}
            </Button>
        </Box>
    </Container>
    );
}