import {React} from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import LinesManagement from './components/pages/LinesManagement';
import LineCalls from './components/LineCalls';
import CategoriesManagement from './components/pages/CategoriesManagement';
import WatchSystem from './components/WatchSystem';
import {UserAccount} from './components/pages/UserAccount';
import {Login} from './components/pages/Login';
import {Logout} from './components/pages/Logout';
import AuthRoute from './components/routes/AuthRoute';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from '@mui/material';
import { hideErrorDialog } from "./features/errorDialog";
import { LoginByToken } from './components/pages/LoginByToken';

function App() {
  const user = useSelector((state) => state.user.value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorDialog = useSelector((state) => state.errorDialog.value);

  const handleErrorDialogClose = () => {
    dispatch(hideErrorDialog());
  };

  return (
    <>
{/*       <Helmet>
          <title>{t("Kehilot - Customers Interface")}</title>
      </Helmet> */}
      <Router>
        <Navbar />
        <Switch> 
        <Route path='/' element={<AuthRoute isLoggedIn={user.isLoggedIn}><LinesManagement /></AuthRoute>} />
          <Route path='/lines-management' element={<AuthRoute isLoggedIn={user.isLoggedIn}><LinesManagement /></AuthRoute>} />
          <Route path='/calls-history' element={<AuthRoute isLoggedIn={user.isLoggedIn}><LineCalls line={{isMain: true}}/></AuthRoute>} />
          <Route path='/blocks-management' element={<AuthRoute isLoggedIn={user.isLoggedIn}><CategoriesManagement /></AuthRoute>} />
          <Route path='/watch-system' element={<AuthRoute isLoggedIn={user.isLoggedIn}><WatchSystem entityType="customer" /></AuthRoute>} />
          <Route path='/user-account' element={<AuthRoute isLoggedIn={user.isLoggedIn}><UserAccount /></AuthRoute>} />
          <Route path='/login' element={<Login />} />
          <Route path='/login-by-token' element={<LoginByToken />} />
          <Route path='/logout' element={<AuthRoute isLoggedIn={user.isLoggedIn}><Logout /></AuthRoute>} />
        </Switch>
      </Router>
      <Dialog open={errorDialog.isOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>{t(errorDialog.title)}</DialogTitle>
        <DialogContent>
        <Typography component="div">{t(errorDialog.text)}</Typography>
        </DialogContent>
        <DialogActions> 
          <Button  variant="contained"onClick={handleErrorDialogClose} size="large">{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
