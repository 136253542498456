import React, {useState, useEffect} from 'react';
import { Button, Container, TextField, Backdrop, CircularProgress, Switch} from '@mui/material';
import { Box, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logout } from "../../features/user";
import {handleApiErrorMessage} from "../../Helper";
import { showErrorDialog } from "../../features/errorDialog";
import Consent from "../Consent";

export default function Service(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const initialSetting = {
    sendMail: false,
    specificMail: "",
    recordInCalls: true,
    recordOutCalls: true
  };
  const [setting, setSetting] = useState(initialSetting);

  const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [removalLoaderVisible, setRemovalLoaderVisible] = useState(false);
  const [removalApproved, setRemovalApproved] = useState(false);

  const [showIllegalEmailError, setShowIllegalEmailError] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [missingRequiredFieldsInRemoval, setMissingRequiredFieldsInRemoval] = useState({
    consent: false
  });

  useEffect(() => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    axios.get(API_URL + '/callsRecords/'+props.lineId+'/setting', {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
          setSetting(data.setting);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
      }).catch(() => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
    });
  }, []);

  const displayBeforeRemovalPopup = () => {
    setRemovalDialogOpen(true);
  }

  const handleSave = () => {
    var validEmailRegex = /^\S+@\S+\.\S+$/;
    if(setting.sendMail === true && setting.hasOwnProperty("specificMail") && (setting.specificMail !=="") && !validEmailRegex.test(setting.specificMail)){
      setShowIllegalEmailError(true);
      return;
    }
    setShowIllegalEmailError(false);

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/callsRecords/'+props.lineId+'/setting';

    setDialogLoaderVisible(true);

    axios.put(apiUrl, 
      {setting: setting}, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
            setShowSuccessMessage(true);
            setDialogLoaderVisible(false);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
  };

const handleRemovalDialogClose = () => {
  setRemovalDialogOpen(false);
};

const handleSubmitRemoval = () => {
  setMissingRequiredFieldsInRemoval({
    consent: (!removalApproved)
  });

  let missingFields = (!removalApproved);
  if(missingFields === true){
    return;
  }

  let errorMessage = t(DEFAULT_ERROR_TEXT);

  let  headers = {
    token: cookie.get('token')
  }
  let apiUrl = API_URL + '/callsRecords/'+props.lineId+'/registration';

  setRemovalLoaderVisible(true);

  axios.delete(apiUrl,  
  {headers})
  .then(
    ({ data }) => {
      if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK 
      && data.hasOwnProperty("status") && (data['status'] === true))){
        props.closeDialog();
        setRemovalLoaderVisible(false);
        props.showSuccessMessageInLinesScreen(true);
      }else{
        if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
          errorMessage = data["message"];
        }
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setRemovalLoaderVisible(false);
      }
    }).catch((error) => {
      handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      setRemovalLoaderVisible(false);
  });
};

  return (  
    <Container className="content-wrapper service">
      <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div>{successMessage}</div>
        <IconButton onClick={() => setShowSuccessMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div>{errorMessage}</div>
        <IconButton onClick={() => setShowErrorMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className='setting-wrapper'>
        <div className='item'>
          <div className='title'>{t("Send recordings to email?")}</div>
          <div className='value'>
            <Switch
            checked={setting.sendMail}
            onChange={() => {
              setSetting({...setting, "sendMail":!setting.sendMail});
            }}
            />
          </div>
        </div>
        {(
          setting.sendMail ?
          <div className='item'>
            <div className='title'>{t("Specific email address (if empty, the account email address will be used):")}</div>
            <div className='value'>
              <TextField className="input" size="small" 
              value={setting.specificMail}
              onChange={(e) => {
                setSetting({...setting, "specificMail":e.target.value});
                }}
              />
              {(
                showIllegalEmailError ? 
                <div className='error'>{t('Please insert a valid email')}</div> : ''
              )}
            </div>
          </div> : ''
        )}
        <div className='item'>
          <div className='title'>{t("Record incoming calls")}</div>
          <div className='value'>
            <Switch
            checked={setting.recordInCalls}
            onChange={() => {
              setSetting({...setting, "recordInCalls":!setting.recordInCalls});
            }}
            />
          </div>
        </div>
        <div className='item'>
          <div className='title'>{t("Record outcoming calls")}</div>
          <div className='value'>
            <Switch
            checked={setting.recordOutCalls}
            onChange={() => {
              setSetting({...setting, "recordOutCalls":!setting.recordOutCalls});
            }}
            />
          </div>
        </div>
      </div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoaderVisible}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleSave()}
          className="agree"
      >
          {t('Save')}
      </Button>
      <div className='removal'>
        <div className='title'>{t('Remove from service')}</div>
        <Button
            variant="contained"
            color="primary"
            onClick={(e) => displayBeforeRemovalPopup()}
        >
            {t('Remove from service')}
        </Button>
      </div>
      <Dialog open={removalDialogOpen} onClose={handleRemovalDialogClose} 
          fullWidth
          maxWidth={'2000px'}
            >
        <DialogTitle>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
            <div>{t("Removal of the line {{line}} from the recordings service",{line: props.lineNumber} )}</div>
            <IconButton onClick={handleRemovalDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent >
          <div className='removal-message'>
            <div className='red'>{t("Note that the service removal will make it impossible to listen to recordings and make new ones.")}</div>
          </div>
          <Consent label={t("I understand the consequences of the service removal and interested to remove it.")}
            approved={removalApproved}
            missingRequiredFields={missingRequiredFieldsInRemoval}
            changeApproved={setRemovalApproved}
             />
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmitRemoval()}
            className="agree"
        >
            {t('Submit')}
        </Button>
        </DialogContent>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={removalLoaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </Container>
  );
}
