import React from 'react';
import App from './App';
import { createRoot } from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import userReducer from "./features/user";
import errorDialogReducer from "./features/errorDialog";
import { ThemeProvider, createTheme } from '@mui/material';
import { heIL } from '@mui/material/locale';
import './i18n/config';    

const store = configureStore({
    reducer: {
      user: userReducer,
      errorDialog: errorDialogReducer
    },
  });


const theme = createTheme({
    palette :{
        primary : {
            main : "#000"
        }
    }
}, heIL);


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
document.documentElement.setAttribute("dir","rtl");
root.render(
    <Provider store={store}>
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
    </Provider>
);