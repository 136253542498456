import React, {useState, useEffect, useMemo} from 'react';
import { Button, Container, Backdrop, CircularProgress, Switch} from '@mui/material';
import { Box, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logout } from "../../features/user";
import {handleApiErrorMessage} from "../../Helper";
import { showErrorDialog } from "../../features/errorDialog";
import Consent from "../Consent";
import countryList from 'react-select-country-list';
import SendNumber from './SendNumber';

export default function Service(props) {
  const MESSAGE_LOCATION_SETTING = "setting";
  const MESSAGE_LOCATION_REPORT = "report";
  const MESSAGE_LOCATION_WHITELIST = "whitelist";
  const MESSAGE_LOCATION_WHITELIST_IMPORT = "whitelist_import";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const initialSetting = {
    VALID_PHONE_ONLY_MOBILE: false
  };
  const [setting, setSetting] = useState(initialSetting);

  const [removalDialogOpen, setRemovalDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removalApproved, setRemovalApproved] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [successMessageLocation, setSuccessMessageLocation] = useState(MESSAGE_LOCATION_SETTING);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const defaultErrorMessage = t(DEFAULT_ERROR_TEXT);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  const [missingRequiredFieldsInRemoval, setMissingRequiredFieldsInRemoval] = useState({
    consent: false
  });

  useEffect(() => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    axios.get(API_URL + '/antiSpam/'+props.lineId+'/setting', {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
          setSetting(data.setting);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
      }).catch(() => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
    });
  }, []);

  const displayBeforeRemovalPopup = () => {
    setRemovalDialogOpen(true);
  }

  const handleSave = () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/antiSpam/'+props.lineId+'/setting';

    setLoading(true);

    axios.put(apiUrl, 
      setting, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
            setSuccessMessageLocation(MESSAGE_LOCATION_SETTING);
            setShowSuccessMessage(true);
            setLoading(false);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      });
  };

const handleRemovalDialogClose = () => {
  setRemovalDialogOpen(false);
};

const handleSubmitRemoval = () => {
  setMissingRequiredFieldsInRemoval({
    consent: (!removalApproved)
  });

  let missingFields = (!removalApproved);
  if(missingFields === true){
    return;
  }

  let errorMessage = t(DEFAULT_ERROR_TEXT);

  let  headers = {
    token: cookie.get('token')
  }
  let apiUrl = API_URL + '/antiSpam/'+props.lineId+'/registration';

  setLoading(true);

  axios.delete(apiUrl,  
  {headers})
  .then(
    ({ data }) => {
      if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
        props.closeDialog();
        setLoading(false);
        props.showSuccessMessageInLinesScreen(true);
      }else{
        if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
          errorMessage = data["message"];
        }
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
      }
    }).catch((error) => {
      handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
      setLoading(false);
  });
};

  return (  
    <Container className="content-wrapper service">
      {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_SETTING ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
        <div>{errorMessage}</div>
        <IconButton onClick={() => setShowErrorMessage(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <div className='setting-wrapper'>
        <div className='item'>
          <div className='title'>{t("Apply service only on landline?")}</div>
          <div className='value'>
            <Switch
            checked={setting.VALID_PHONE_ONLY_MOBILE}
            onChange={() => {
              setSetting({ "VALID_PHONE_ONLY_MOBILE":!setting.VALID_PHONE_ONLY_MOBILE});
            }}
            />
          </div>
          <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSave()}
              className="agree"
          >
              {t('Save')}
          </Button>
        </div>
      </div>
      {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_REPORT ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <SendNumber
        type="report"
        apiUrl={API_URL + '/antiSpam/'+props.lineId+'/report'}
        setLoading={setLoading}
        messageLocation={MESSAGE_LOCATION_REPORT}
        setSuccessMessageLocation={setSuccessMessageLocation}
        setShowSuccessMessage={setShowSuccessMessage}
        title={t("Report a number")}
        buttonTitle={t('Submit report')}
         />
      {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_WHITELIST ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <SendNumber
        type="whitelist"
        apiUrl={API_URL + '/antiSpam/'+props.lineId+'/whiteList'}
        setLoading={setLoading}
        messageLocation={MESSAGE_LOCATION_WHITELIST}
        setSuccessMessageLocation={setSuccessMessageLocation}
        setShowSuccessMessage={setShowSuccessMessage}
        title={t("Whitelist a number")}
        buttonTitle={t('Submit whitelist')}
        />
      {(
        showSuccessMessage && successMessageLocation === MESSAGE_LOCATION_WHITELIST_IMPORT ?
        <Box className="success-message" sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between', alignItems: "center"  }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box> : ''
      )}
      <SendNumber
        type="whitelist"
        isMany={true}
        apiUrl={API_URL + '/antiSpam/'+props.lineId+'/whiteListImport'}
        messageLocation={MESSAGE_LOCATION_WHITELIST_IMPORT}
        setSuccessMessageLocation={setSuccessMessageLocation}
        setShowSuccessMessage={setShowSuccessMessage}
        title={t("Whitelist numbers")}
        note={t("Please paste a list of numbers")}
        buttonTitle={t('Submit whitelist')}
        setLoading={setLoading}
        />
      <div className='removal'>
        <div className='title'>{t('Remove from service')}</div>
        <Button
            variant="contained"
            color="primary"
            onClick={(e) => displayBeforeRemovalPopup()}
        >
            {t('Remove from service')}
        </Button>
      </div>
      <Dialog open={removalDialogOpen} onClose={handleRemovalDialogClose} 
          fullWidth
          maxWidth={'2000px'}
            >
        <DialogTitle>
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
            <div>{t("Removal of the line {{line}} from the anti spam service",{line: props.lineNumber} )}</div>
            <IconButton onClick={handleRemovalDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent >
          <Consent label={t("I understand the consequences of the service removal and interested to remove it.")}
            approved={removalApproved}
            missingRequiredFields={missingRequiredFieldsInRemoval}
            changeApproved={setRemovalApproved}
             />
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmitRemoval()}
            className="agree"
        >
            {t('Submit')}
        </Button>
        </DialogContent>
      </Dialog>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
