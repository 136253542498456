import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, LIABILITY_POPUP_APPROVED, APP_NAME } from '../../Constants';
import { Backdrop, CircularProgress, Typography} from '@mui/material';
import {Stack, Button, Select, MenuItem, Box, Dialog, IconButton, TextField, Container} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid , heIL } from '@mui/x-data-grid';
import '../../styles.css';
import cookie from "js-cookie";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import {handleApiErrorMessage} from "../../Helper";
import Category from "../Category";


export default function CategoriesManagement() {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const approvedLiablilityPopup = (localStorage.getItem(LIABILITY_POPUP_APPROVED) === "1");
  const [liabilityDialogIsOpen, setLiabilityDialogIsOpen] = useState(!approvedLiablilityPopup);
  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const initalSelectedCategoryValue = {
    id: null,
    name: null,
    note: null,
    public: 1,
    privacy_level: 0,
    isOwner: 1,
    myJoin: 0,
    valid: 0,
    active_setting: 0
  };
  const [selectedCategory, setSelectedCategory] = useState(initalSelectedCategoryValue);
  const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState(initalSelectedCategoryValue);

  const kehilaCategoryData = {
    id: 0,
    name: t('My Category'),
    note: '',
    public: 0,
    create_date: '',
    isOwner: 1,
    myJoin: 1,
    valid: 1,
    active_setting: 0
  };

  const [selectionIds, setSelectionIds] = useState(false);

  const initialSearchInput = {
    name : "",
    onlyOwner: false,
    onlyMy: false,
    onlyValid: false
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const DEAFULT_PAGE_SIZE = 50;
  const [pageSize, setPageSize] = useState(DEAFULT_PAGE_SIZE);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: 'name', headerName: t('Name'), width: 330, flex: 1},
    { field: 'verified', headerName: t('Verified category'), width: 130, flex: 1, 
      renderCell: (cellValues) => {
        return (
          cellValues.row.valid === 1 ?
          <div className="check-wrapper">
            <div className="check"></div>
          </div>
          : ''
        );
      }
    },
    { field: 'public', type: 'boolean', headerName: t('Is Public'), width: 130, flex: 1,  sortable:false},
    { field: 'create_date', headerName: t('Created at'), width: 330, flex: 1, filterable:false,  sortable:false },
    { field: 'isOwner', type: 'boolean', headerName: t('Is Owner'), width: 130, flex: 1,  sortable:false},
    { field: 'myJoin', type: 'boolean', headerName: t('Is Joined'), width: 130, flex: 1,  sortable:false},
    { field: 'active_setting', headerName: t('Active numbers in the list'), width: 130, flex: 1,  sortable:false},
    { 
      field: 'edit', headerName: '', width: 330, flex: 1,  sortable:false, filterable:false,
      renderCell: (cellValues) => {
        return (
          <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleViewRow(e, cellValues)}
          >
              {t('View')}
          </Button>
        );
      }
    },
    ,
    { 
      field: 'delete', headerName: '', width: 330, flex: 1,  sortable:false, filterable:false,
      renderCell: (cellValues) => {
        const classNameValue = (cellValues.row.isOwner !== 1 || cellValues.id === 0 ? 'locked' : '');
        return (
          <svg 
          className={classNameValue}
          onClick={cellValues.row.isOwner === 1 && cellValues.id !== 0 ? ((e) => handleDeleteRow(e, cellValues)) : ((e) => {return;})}
          width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.016 4.38888H4.98389V0.927341C4.98389 0.416204 5.39526 0 5.90156 0H12.0983C12.6042 0 13.016 0.416204 13.016 0.927341V4.38888ZM5.63852 3.72735H12.3609V0.927341C12.3609 0.780945 12.2427 0.66153 12.0979 0.66153H5.90156C5.75669 0.66153 5.63902 0.780945 5.63902 0.927341V3.72735H5.63852Z" fill="black"/>
          <path d="M15.4173 19H2.58174C2.07593 19 1.66406 18.5843 1.66406 18.0732V4.65469C1.66406 4.14355 2.07593 3.72735 2.58174 3.72735H15.4173C15.9236 3.72735 16.3355 4.14355 16.3355 4.65469V18.0732C16.3355 18.5843 15.9236 19 15.4173 19ZM2.58174 4.38888C2.43637 4.38888 2.3187 4.50829 2.3187 4.65469V18.0732C2.3187 18.2196 2.43687 18.3385 2.58174 18.3385H15.4173C15.5622 18.3385 15.6809 18.2196 15.6809 18.0732V4.65469C15.6809 4.50829 15.5627 4.38888 15.4173 4.38888H2.58174Z" fill="black"/>
          <path d="M18 3.72735H0V4.38888H18V3.72735Z" fill="black"/>
          <path d="M9.327 7.22636H8.67236V15.4265H9.327V7.22636Z" fill="black"/>
          <path d="M5.78647 7.22636H5.13184V15.4265H5.78647V7.22636Z" fill="black"/>
          <path d="M12.8685 7.22636H12.2139V15.4265H12.8685V7.22636Z" fill="black"/>
          </svg>
        );
      }
    }
  ];

  const handleDeleteRow = (e, cellValues) => {
    setSelectedCategoryToDelete({
      id: cellValues.row.id,
      name: cellValues.row.name
    });
    setDeleteDialogIsOpen(true);
  };

  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshCategoriesData();
  }, [page, pageSize, selectedCategory.id]);


  const resetDialogHideLoader = () => {
    setDialogLoaderVisible(false);
    setDialogIsOpen(false);
    setSelectedCategory(initalSelectedCategoryValue);
  };

    const refreshCategoriesData = (pageToStart = null) => {
      if(pageToStart === null){
        pageToStart = page;
      }
      let errorMessage = t(DEFAULT_ERROR_TEXT);
      setLoading(true);

      let  headers = {
        token: cookie.get('token')
      }

      setDialogLoaderVisible(true);

      let currentPageSize = pageSize;
      if(pageToStart === 0){
        currentPageSize = pageSize-1;
      }
      let currentStart = pageToStart*pageSize;
      if(pageToStart > 0){
        currentStart = currentStart-1;
      }

      axios.post(API_URL + '/blockList/categories/list', 
      {
        start: currentStart, 
        limit: currentPageSize, 
        search: searchInput.name, 
        onlyOwner: searchInput.onlyOwner,
        onlyMy: searchInput.onlyMy,
        onlyValid: searchInput.onlyValid
      }, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("list"))){
            let categoriesList = data.list;
            if(pageToStart === 0){
              kehilaCategoryData.active_setting = data.main_my_categories.active_setting;
              categoriesList.unshift(kehilaCategoryData);
            }
            setRows(categoriesList);
            setRowCount(data.totalCount+1);
            setLoading(false);
            setDialogLoaderVisible(false);
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
          setDialogLoaderVisible(false);
      });
    };


  const handleViewRow = (e, cellValues) => {
    setSelectedCategory({
      id: cellValues.row.id,
      name: cellValues.row.name,
      note: cellValues.row.note,
      isOwner: Number.parseInt(cellValues.row.isOwner),
      myJoin: Number.parseInt(cellValues.row.myJoin),
      valid: Number.parseInt(cellValues.row.valid),
      public: Number.parseInt(cellValues.row.public),
      privacy_level: Number.parseInt(cellValues.row.privacy_level),
      active_setting: Number.parseInt(cellValues.row.active_setting)
    });
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    resetDialogHideLoader();
  };

  const handleAddRow = () => {
    setDialogIsOpen(true);
  };

  
  const handleRemoveBelongsRows = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    if(!selectionIds){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
    
    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }
    
      const data = {
        ids: selectionIds.map((id) => {return ''+id;})
      };

      let apiUrl = API_URL + '/blockList/categories/leave';

      axios.post(apiUrl, data, {headers})
      .then(
         (responseData) => {
          if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
            refreshCategoriesData();
          }else{
            if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
          resetDialogHideLoader();
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);

          resetDialogHideLoader();
      });
  };

  const handleAddBelongsRows = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    if(!selectionIds){
      alert(t('No lines selected'));
      return;
    }else{
      let nonRemovedRows = rows.filter((row) => (!selectionIds.includes(row.id)));
      if(nonRemovedRows.length === rows.length){
        alert(t('No lines selected'));
        return;
      }
    }
    
    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }
    
      const data = {
        ids: selectionIds.map((id) => {return ''+id;})
      };

      let apiUrl = API_URL + '/blockList/categories/join';

      axios.post(apiUrl, data, {headers})
      .then(
         (responseData) => {
          if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
            setShowSuccessMessage(true);
            setSelectionIds([]);
            refreshCategoriesData();
          }else{
            if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          }
          resetDialogHideLoader();
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);

          resetDialogHideLoader();
      });
  };

  const changeSearchFilters = (e, fieldName, type = null) => {
    let newSearchInput = {
      name : searchInput.name,
      onlyOwner: searchInput.onlyOwner,
      onlyMy: searchInput.onlyMy
    };
    if(type === "boolean"){
      newSearchInput[fieldName] = (e.target.value === "0" ? false : true);
    }else{
      newSearchInput[fieldName] = e.target.value;
    }
    
    setSearchInput(newSearchInput);
  };

  const handleSearchSubmit = (e) => {
    refreshCategoriesData(0);
  };


  const handleCloseLiabilityDialog = () => {
    localStorage.setItem(LIABILITY_POPUP_APPROVED, "1");
    setLiabilityDialogIsOpen(false);
  }

  const handleDeleteRowSubmit = async () => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }
    

      let apiUrl = API_URL + '/blockList/categories/'+selectedCategoryToDelete.id+'/delete';

      axios.get(apiUrl, {headers})
      .then(
         (responseData) => {
          if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
            refreshCategoriesData();
            setDialogLoaderVisible(false);
            setDeleteDialogIsOpen(false);
            setShowSuccessMessage(true);
          }else{
            if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
              errorMessage = responseData.data["message"];
            }
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          setErrorMessage(errorMessage);
          setShowErrorMessage(true);
          setDialogLoaderVisible(false);
      });
  };

  return (  
    <Container maxWidth="xl" className="content-wrapper">
            <>
            <div className={"notice-message"} id="liability_message">
                <div style={{marginBottom : '10px'}}>
                  {t("Dear community manager, please note that the use of the categories is under your sole responsibility. The {{company}} is not repsonsilbe of the categories and their listings.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}
                </div>
            </div>
            <Box sx={{border: 1, pr: 2, pl: 2, mb: 2,  borderColor: 'grey.300', borderRadius: '1%'}}>
              <Box>
              <Typography variant="h6">{t("Search box")}</Typography>
              </Box>
              <Stack spacing={1} 
                direction="row" 
                flexWrap="wrap"
                sx={[
                  {
                    mb: 2,
                    flexWrap: "wrap"
                }
              ]}
                >
              <Box sx={{ml: 2}}>
                  <Typography>{t('Name')}</Typography>
                  <TextField size="small" id="search-name" value={searchInput.name} onChange={(e) => changeSearchFilters(e, "name")} />
              </Box>
              <Box direction="row" sx={{ml: 2}}>
                  <Typography>{t('Is Owner')}</Typography>
                  <Select
                    size="small"
                    value={(searchInput.onlyOwner ? "1" : "0")}
                    onChange={(e) => changeSearchFilters(e, "onlyOwner", "boolean")}
                    sx={{width: "210px"}}
                  >
                    <MenuItem value="1">{t("Yes")}</MenuItem>
                    <MenuItem value="0">{t("No")}</MenuItem>
                  </Select>
              </Box>
              <Box direction="row" sx={{ml: 2}}>
                <Typography>{t('Is Joined')}</Typography>
                  <Select
                    size="small"
                    value={(searchInput.onlyMy ? "1" : "0")}
                    onChange={(e) => changeSearchFilters(e, "onlyMy", "boolean")}
                    sx={{width: "210px"}}
                  >
                    <MenuItem value="1">{t("Yes")}</MenuItem>
                    <MenuItem value="0">{t("Show All")}</MenuItem>
                  </Select>
              </Box>
              <Box direction="row" sx={{ml: 2}}>
                <Typography>{t('Only Verified Categories')}</Typography>
                  <Select
                    size="small"
                    value={(searchInput.onlyValid ? "1" : "0")}
                    onChange={(e) => changeSearchFilters(e, "onlyValid", "boolean")}
                    sx={{width: "210px"}}
                  >
                    <MenuItem value="1">{t("Yes")}</MenuItem>
                    <MenuItem value="0">{t("Show All")}</MenuItem>
                  </Select>
              </Box>
              <Box direction="row">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleSearchSubmit(e)}
                    sx={{width: 210, mt: 3}}
                >
                    {t('Search')}
                </Button>
                </Box>
              </Stack>
              </Box>
            <Button size="medium" onClick={handleAddRow}
                 sx={[
                    {
                      bgcolor: 'green',
                      color: 'white'
                  },
                  {
                    '&:hover': {
                      bgcolor: 'lightgreen',
                      color: 'white'
                    },
                  },
                  {
                    mb: 2
                }
                ]}>
                  {t('Create a new category')}
                </Button>
            <Stack direction="row" spacing={1}
              sx={[
                {
                  mb: 2
              }
            ]}
              >
                <Button size="medium" onClick={handleAddBelongsRows}
                      sx={[
                      {
                        bgcolor: 'gray',
                        color: 'white',
                        ml: 2
                    },
                    {
                      '&:hover': {
                        bgcolor: '#a7a7a7',
                        color: 'white'
                      },
                    }
                  ]}
                >
                  {t('Add belongs to categories')}
                </Button>
                <Button size="medium" onClick={handleRemoveBelongsRows}
                      sx={[
                      {
                        bgcolor: 'gray',
                        color: 'white',
                        ml: 2
                    },
                    {
                      '&:hover': {
                        bgcolor: '#a7a7a7',
                        color: 'white'
                      },
                    }
                  ]}
                >
                  {t('Remove belongs to categories')}
                </Button>
              </Stack>
              <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
                <div>{successMessage}</div>
                <IconButton onClick={() => setShowSuccessMessage(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box className="error-message" sx={{ flexGrow: 1, display: (showErrorMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center"  }}>
                <div>{errorMessage}</div>
                <IconButton onClick={() => setShowErrorMessage(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
                <DataGrid 
                disableSelectionOnClick={true}
                rowCount={rowCount}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                onSelectionModelChange={(ids) => {
                  setSelectionIds(ids);
                }}
                isRowSelectable={(params) => (params.row.id > 0)}
                localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                paginationMode="server"
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
                loading={loading}
                />
              <Dialog open={dialogIsOpen} onClose={handleDialogClose} 
              fullWidth
              maxWidth={'2000px'}
                >
                <DialogTitle>
                  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
                    <div>{t("Category Information")}</div>
                    <IconButton onClick={handleDialogClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  </DialogTitle>
                <DialogContent  style={{height:'80vh'}}>
                  <Category category={selectedCategory} />
                </DialogContent>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={dialogLoaderVisible}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Dialog>
              <Dialog open={liabilityDialogIsOpen} onClose={handleCloseLiabilityDialog} 
                  >
                  <DialogTitle>
                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
                      <div>{t("Please agree")}</div>
                    </Box>
                    </DialogTitle>
                  <DialogContent>
                  <div style={{marginBottom : '10px'}}>
                    {t("Dear community manager, please note that the use of the categories is under your sole responsibility. The company is not repsonsilbe of the categories and their listings.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}
                  </div>
                  <Button size="medium" onClick={handleCloseLiabilityDialog}
                        variant="contained"
                        color="primary"
                  >{t('I Approve')}</Button>
                  </DialogContent>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={dialogLoaderVisible}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
              </Dialog>
              <Dialog open={deleteDialogIsOpen} onClose={() => {setDeleteDialogIsOpen(false)}} 
              fullWidth
                >
                <DialogTitle>
                  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between'  }}>
                    <div>{t("Remove the category {{category}}", {category: selectedCategoryToDelete.name})}</div>
                    <IconButton onClick={() => {setDeleteDialogIsOpen(false)}}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  </DialogTitle>
                <DialogContent>
                  <Box sx={{mb: 2}}>
                    <Typography variant="p" className="red-bold">{t('This action will remove the category permanently! All customers in this category will be deleted!')}</Typography>
                  </Box>
                  <Box sx={{mb: 2}}>
                    <Typography variant="p">{t('Are you sure that you want to proceed with the action?')}</Typography>
                  </Box>
                  <Stack direction="row" spacing={1}
                    sx={[
                      {
                        mb: 2
                    }
                  ]}
                    >
                      <Button size="medium" onClick={handleDeleteRowSubmit}
                            sx={[
                            {
                              bgcolor: 'red',
                              color: 'white',
                              ml: 2
                          },
                          {
                            '&:hover': {
                              bgcolor: '#ff7961',
                              color: 'white'
                            },
                          }
                        ]}
                      >
                        {t('Yes, proceed')}
                      </Button>
                      <Button 
                      variant="contained"
                      color="primary"
                      size="medium" onClick={() => {setDeleteDialogIsOpen(false)}}>
                          {t('Cancel')}
                      </Button>
                    </Stack>
                </DialogContent>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={dialogLoaderVisible}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Dialog>
          </>
    </Container>
  );
}
