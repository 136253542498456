import { createSlice } from "@reduxjs/toolkit";
import cookie from "js-cookie";



const initialStateValue = { isLoggedIn: false};

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    if(cookie.get('token')){
      return {value: { isLoggedIn: true}};
    }else{
      return {value: initialStateValue};
    }
  },
  reducers: {
    login: (state, action) => {
        state.value.isLoggedIn = true;
        cookie.set('token', action.payload.token);
        cookie.set('is_logged_in', '1');
    },

    logout: (state) => {
      state.value = initialStateValue;
      cookie.remove('token');
      cookie.remove('is_logged_in');
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;