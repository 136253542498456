import * as React from 'react';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function Package(props) {
    const { t } = useTranslation();

    return (
        <Box sx={{border: 1, borderColor: "lightgray", p: 2}}>
            {
                props.package.is_main_package === 1  ? 
                <Box sx={{ display: "flex", width: "fit-content", m: "auto", mb: 1 }}>
                    <Typography sx={{
                        padding: 1, 
                        ml: 1, 
                        backgroundColor: "#0077ff", 
                        color: "white", 
                        fontSize: "15px",
                        borderRadius: "10px",
                        fontWeight: "bold"
                    }}>{t('Main package')}</Typography>
                </Box> : 
                ''
            }
            <Box><Typography sx={{mb: 1, fontSize: 20}}><strong>{props.package.package_name}</strong></Typography></Box>
            <Box className='details-rows'>
                <Typography sx={{mb: 1}}><strong>{t('Package ID')}:</strong>{props.package.package_id}</Typography>
                <Typography sx={{mb: 1}}><strong>{t('Customer Package ID')}:</strong>{props.package.customer_package_id}</Typography>
            </Box>
            <Box className='details-rows' sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Typography sx={{mb: 1}}><strong>{t('Minutes in Package')}:</strong>{props.package.call_units_in_package / 60000}</Typography>
                    <Typography sx={{mb: 1}}><strong>{t('Number of previous charges')}:</strong>{props.package.count_renewal}</Typography>
                    {/* <Typography sx={{mb: 1}}><strong>{t('SMS in Package')}:</strong>{props.package.sms_units_in_package}</Typography> */}
                    {/* <Typography sx={{mb: 1}}><strong>{t('Internet in Package')}:</strong>{props.package.net_units_in_package}</Typography> */}
                </Box>
                <Box>
                    <Typography sx={{mb: 1}}><strong>{t('Minutes balance')}:</strong>{(props.package.call_balance / 60000).toFixed(2)}</Typography>
                    <Typography sx={{mb: 1}}><strong>{t('Last charge date')}:</strong>{props.package.last_billing}</Typography>
                    {/* <Typography sx={{mb: 1}}><strong>{t('SMS balance')}:</strong>{props.package.sms_balance}</Typography> */}
                    {/* <Typography sx={{mb: 1}}><strong>{t('Internet balance')}:</strong>{props.package.net_balance}</Typography> */}
                </Box>
                <Box>
                    <Typography sx={{mb: 1}}><strong>{t('Next charge amount')}:</strong>{props.package.next_price}</Typography>
                </Box>
            </Box>
            <Box><Typography sx={{mb: 1, mt:2, textAlign: "center"}}>{t('Created at')} <strong>{props.package.create_date}</strong></Typography></Box>
        </Box>
    );
}