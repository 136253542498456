import React, {useState} from 'react';
import { Button, Container, Backdrop, CircularProgress} from '@mui/material';
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT,APP_NAME } from '../../Constants';
import cookie from "js-cookie";
import axios from "axios";
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logout } from "../../features/user";
import {handleApiErrorMessage} from "../../Helper";
import Consent from "../Consent";
import { showErrorDialog } from "../../features/errorDialog";


export default function SignUp(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [termsApproved, setTermsApproved] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [missingRequiredFields, setMissingRequiredFields] = useState({
    consent: false
  });
  const initialSetting = {
    sendMail: false,
    recordInCalls: true,
    recordOutCalls: true
  };

  const handleSubmit = () => {
    setMissingRequiredFields({
      consent: (!termsApproved)
    });

    let missingFields = (!termsApproved);
    if(missingFields === true){
      return;
    }

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    let  headers = {
      token: cookie.get('token')
    }

    let apiUrl = API_URL + '/callsRecords/'+props.lineId+'/registration';

    setLoaderVisible(true);

    axios.post(apiUrl, 
      {setting : initialSetting}, 
      {headers})
      .then(
        ({ data }) => {
          if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK)){
            setLoaderVisible(false);
            props.closeDialog();
            props.displayRecordingScreen();
          }else{
            if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoaderVisible(false);
      });
  };

  return (  
    <Container className="content-wrapper sign-up-to-service">
        <div className='terms'>
          <div>{t("Dear customer, you are not registered to the call recordings service")}</div>
          <div className='title'>{t("Registration to the service:")}</div>
          <div>{t("The service allows calls recording of the lines.")}</div>
          <ul>
            <li>{t("The system will record your calls and keep them for 30 days.")}</li>
            <li>{t("The service is limited to 15GB of space per user.")}</li>
            <li>{t("The service costs 10 NIS per month. In the first month you get a 50% discount.")}</li>
            <li>{t("The service registration is for at least two months. You can't delete the service for the first two months.")}</li>
            <li>{t("After the removal of the service you won't be able to listen to the recordings.")}</li>
            <li>{t("The service is a subject to the terms of use of {{company}}.", {company : t(APP_NAME), interpolation: { escapeValue: false }})}</li>
          </ul>
          <Consent label={t("I approve that I understood the terms and the costs of the service and is interested to sign up.")}
            approved={termsApproved}
            missingRequiredFields={missingRequiredFields}
            changeApproved={setTermsApproved}
             />
        </div>
        <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit()}
            className="agree"
        >
            {t('Agree and register to the service')}
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderVisible}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
  );
}
