import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../features/user";
import { showErrorDialog } from "../../features/errorDialog";
import axios from "axios";
import { API_OK, API_URL, API_EXCEPTION, DEFAULT_ERROR_TEXT } from '../../Constants';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {handleApiErrorMessage} from "../../Helper"
import { logout } from "../../features/user";
import { useSearchParams } from "react-router-dom";
import cookie from "js-cookie";


export function LoginByToken() {
    const [loginSuccessful, setLoginSuccessful] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        let tokenParam = searchParams.get("token");
        let continueRoute = searchParams.get("continue");
        if(tokenParam !== null && (typeof tokenParam !== "undefined")){
            let  headers = {
                token: tokenParam
            }

            let errorMessage = t(DEFAULT_ERROR_TEXT);

            axios.get(API_URL + '/my', {headers})
            .then(
                ({ data }) => {
                if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK) ){
                    dispatch(login({token : tokenParam}));
                    if(continueRoute !== null && (typeof continueRoute !== "undefined")){
                        navigate("/"+continueRoute);
                    }else{
                        navigate("/user-account");
                    }
                }else{
                    if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
                    errorMessage = data["message"];
                    }
                    handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, null, navigate);
                }
                }).catch((error) => {
                handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, null, navigate);
            });
        }
      }, []);

    return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={[
                    {
                      mt: 2
                  }
                ]}
            >
                <div>{(loginSuccessful ? "Login Successful" : "Login Required")}</div>
            </Box>
    );
}